import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IDirectiveLinkFn, INgModelController, IScope, ITranscludeFunction } from "angular";
import { untilScopeDestroyed } from "@gtmhub/core/rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";

type BooleanEvaluator = () => boolean;

type HasPermissionWithFeatureFlagDirectiveAttributes = IAttributes & {
  ngIf?: BooleanEvaluator;
  hasPermissionWhen: keyof typeof FeatureFlag;
  negate?: string;
  flagIsOn: Permission;
  flagIsOff: Permission;
};

// example usage:
// <span has-permission-when="ManageOKRsGranularPermissions" flag-is-on="goals:create" flag-is-off="ManageGoals">{{ "time_define_goal" | localize }}</span>
// <span has-permission-when="ManageOKRsGranularPermissions" flag-is-on="goals:create" flag-is-off="ManageGoals" negate="true">{{ "no_permit_define_new_objs" | localize }}</span>
export class HasPermissionWithFeatureFlagDirective implements IDirective {
  public restrict = "A";
  public transclude = this.ngIf.transclude;
  public priority = this.ngIf.priority - 2;
  public terminal = true;

  constructor(
    private ngIf: IDirective,
    private permissionsFacade: PermissionsFacadeWithFeatureFlag
  ) {}

  public link(
    scope: IScope,
    element: IAugmentedJQuery,
    attrs: HasPermissionWithFeatureFlagDirectiveAttributes,
    ctrl: INgModelController,
    transclude: ITranscludeFunction
  ): void {
    let hasPermissionResult: boolean = null;

    const initialNgIfEvaluator: BooleanEvaluator = attrs.ngIf;
    const permissionIfEvaluator: BooleanEvaluator = () => {
      // until the promise is resolved, always negate the if-expression
      const isWaitingForPromise = typeof hasPermissionResult !== "boolean";

      return !isWaitingForPromise && hasPermissionResult;
    };

    if (initialNgIfEvaluator) {
      attrs.ngIf = () => permissionIfEvaluator() && scope.$eval(initialNgIfEvaluator);
    } else {
      attrs.ngIf = () => permissionIfEvaluator();
    }

    attrs.$observe<string>("hasPermissionWhen", (hasPermissionWhen) => {
      const featureFlag = FeatureFlag[hasPermissionWhen];

      this.permissionsFacade
        .hasPermissionWithFeatureFlag$(featureFlag, attrs.flagIsOn, attrs.flagIsOff)
        .pipe(untilScopeDestroyed(scope))
        .subscribe((hasPermission) => {
          const shouldNegate = attrs.negate === "true";
          hasPermissionResult = hasPermission !== shouldNegate;
          scope.$evalAsync();
        });
    });

    const ngIfLinkFn = this.ngIf.link as IDirectiveLinkFn;
    ngIfLinkFn.apply(this.ngIf, [scope, element, attrs, ctrl, transclude]);
  }

  public static factory(): IDirectiveFactory {
    const directive = (ngIfDirective: IDirective[], permissionsFacade: PermissionsFacadeWithFeatureFlag) =>
      new HasPermissionWithFeatureFlagDirective(ngIfDirective[0], permissionsFacade);
    directive.$inject = ["ngIfDirective", "PermissionsFacadeWithFeatureFlag"];
    return directive;
  }
}

import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";
import { CurrentUserRepository } from "@webapp/users/services/current-user-repository/current-user-repository.service";

type NegateParam = { negate: boolean | undefined };

@Injectable({ providedIn: "root" })
export class GranularCommentsAndReactionsPermissionsService {
  constructor(
    private permissionsFacade: PermissionsFacadeWithFeatureFlag,
    private currentUserRepository: CurrentUserRepository
  ) {}

  public hasPermissionToManageOwnCommentsAndReactions$(params: NegateParam = { negate: false }): Observable<boolean> {
    const isCurrentUserViewOnly = this.currentUserRepository.userHasRole("view-only");

    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "comments_and_reactions:manage_own",
      permissionWhenFlagIsOff: !isCurrentUserViewOnly,
    });
  }

  public hasPermissionToDeleteAnyComment$(params: NegateParam = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "comments:delete",
      permissionWhenFlagIsOff: false,
    });
  }

  private hasPermissionTo$({
    negate,
    permissionWhenFlagIsOn,
    permissionWhenFlagIsOff,
  }: {
    negate: boolean | undefined;
    permissionWhenFlagIsOn: Permission | boolean;
    permissionWhenFlagIsOff: Permission | boolean;
  }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;

    return this.permissionsFacade
      .hasPermissionWithFeatureFlag$(FeatureFlag.ManageCommentsAndReactionsGranularPermissions, permissionWhenFlagIsOn, permissionWhenFlagIsOff)
      .pipe(
        map((hasPermission) => {
          return hasPermission !== shouldNegate;
        })
      );
  }
}

import { IRootScopeService, IScope } from "angular";
import { IGoal, SingleGoalSelectorInvokedBy } from "@gtmhub/goals/models";
import { ITag } from "@gtmhub/tags/models";
import { CascadingLink, GoalLink, LinksDTO } from "@webapp/links/models/links.models";
import { OkrStatus } from "@webapp/okrs/goals/models/goal-status.models";
import { ICheckin, Metric } from "@webapp/okrs/metrics/models/metric.models";
import { Session } from "@webapp/sessions/models/sessions.model";

export const goalSelected = "goalSelected";
export const metricSelected = "metricSelected";

export enum OKRsEventType {
  OKRS_GOALS_CHANGED = "okrs.goalsChanged",
}

export interface IGoalChangedEventArgs {
  reason: "goalCreated" | "goalUpdated" | "goalPatched" | "goalCloned" | "goalMoved" | "goalVisited";
  detail: {
    goal: IGoal;
  };
}

interface IGoalsCloneEventArgs {
  reason: "goalsCloned";
  detail: {
    goals: IGoal[];
  };
}

interface IGoalChangedEvent {
  reason: "goalChanged";
  detail?: {
    goal: IGoal;
  };
}
interface IGoalSelectedEventArgs {
  reason: "goalSelected";
  detail: {
    goal: IGoal;
    invokedById: string;
    invokedBy: SingleGoalSelectorInvokedBy;
  };
}

interface IGoalStatusChangedEventArgs {
  reason: "goalStatusChanged";
  detail: {
    goalId: string;
    status: OkrStatus;
  };
}

interface IGoalChildReorderedEventDetail {
  goalId: string;
  childId: string;
  childType: "metric" | "goal";
  childOrderId: number;
}

export interface IGoalChildReorderedEventArgs {
  reason: "goalChildReordered";
  detail: IGoalChildReorderedEventDetail;
}

interface IGoalDeletedEventArgs {
  reason: "goalDeleted";
  detail: {
    goalId: string;
  };
}

interface IGoalReviewChangedEventArgs {
  reason: "reviewDeclined" | "reviewApproved" | "reviewWithdrawn" | "reviewSentForApproval";
  detail: {
    goalId: string;
  };
}

interface IGoalTagsChangedEventArgs {
  reason: "tagAdded" | "tagRemoved" | "tagsUpdated";
  detail: {
    goalId: string;
    goalIds: string[];
    tag: ITag;
    tags: ITag[];
  };
}

interface IMetricTagsChangedEventArgs {
  reason: "tagAdded" | "tagRemoved" | "tagsUpdated";
  detail: {
    metricId: string;
    metricIds: string[];
    tag: ITag;
    tags: ITag[];
  };
}

interface IGoalLinksChangedEventArgs {
  reason: "goalLinked" | "goalUnlinked";
  detail: {
    goalId: string;
    link: LinksDTO<GoalLink>;
  };
}

export interface IMetricChangedEventArgs {
  reason: "metricCreated" | "metricPatched" | "metricMoved" | "metricVisited" | "metricChanged";
  detail: {
    goalId: string;
    metric: Metric;
    patch?: Partial<Metric>;
  };
}

interface IMetricUpdatedEventsArgs {
  reason: "metricUpdated";
  detail: {
    metric: Metric;
    goal?: IGoal;
    session?: Session;
    checkin?: ICheckin;
  };
}

interface IMetricDeletedEventArgs {
  reason: "metricDeleted";
  detail: {
    goalId: string;
    metricId: string;
  };
}

interface IMetricSnapshotChangedEventArgs {
  reason: "metricSnapshotDeleted";
  detail: {
    goalId: string;
    metricId: string;
    snapshotId: string;
  };
}

interface IMetricSnapshotCreatedEventArgs {
  reason: "metricSnapshotCreated";
  detail: {
    checkin: ICheckin;
    goal: IGoal;
    metric: Metric;
    session: Session;
  };
}

export interface IMetricLinksChangedEventArgs {
  reason: "metricLinked" | "metricUnlinked";
  detail: {
    goalId: string;
    link: LinksDTO<CascadingLink>;
  };
}

interface IMetricCheckinChangedEventArgs {
  reason: "metricCheckin";
  detail: {
    goalId: string;
    metricId: string;
    checkin: ICheckin;
  };
}

interface IMetricSelectEventArgs {
  reason: "metricSelected";
  detail: IMetricSelectEventDetail;
}

interface IMetricSelectEventDetail {
  parent?: Metric | IGoal;
  metric: Metric;
  invokedById: string;
  invokedBy: SingleGoalSelectorInvokedBy;
}

interface IMetricCommentEditEventArgs {
  reason: "metricCommentEdit";
  detail: {
    metric: Metric;
    goal: IGoal;
    session: Session;
  };
}

export type GoalsChangedEventArgs =
  | IGoalChangedEventArgs
  | IGoalChangedEvent
  | IGoalStatusChangedEventArgs
  | IGoalChildReorderedEventArgs
  | IGoalDeletedEventArgs
  | IGoalReviewChangedEventArgs
  | IGoalTagsChangedEventArgs
  | IGoalLinksChangedEventArgs
  | IGoalSelectedEventArgs
  | IGoalsCloneEventArgs
  | IMetricChangedEventArgs
  | IMetricDeletedEventArgs
  | IMetricSnapshotChangedEventArgs
  | IMetricSnapshotCreatedEventArgs
  | IMetricLinksChangedEventArgs
  | IMetricCheckinChangedEventArgs
  | IMetricSelectEventArgs
  | IMetricUpdatedEventsArgs
  | IMetricCommentEditEventArgs
  | IMetricTagsChangedEventArgs;

export type GoalsChangedReason = GoalsChangedEventArgs["reason"];

type GoalsChangedEventType<R extends GoalsChangedReason> = GoalsChangedEventArgs & { reason: R };

export class OkrEvents {
  private $rootScope: IRootScopeService;

  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  broadcastGoalsChanged<R extends GoalsChangedReason>(reason: R, detail?: GoalsChangedEventType<R>["detail"]): void {
    const args = { reason, detail };
    this.$rootScope.$broadcast(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
  }

  onGoalsChanged(callback: (args: GoalsChangedEventArgs) => unknown): void {
    this.$scope.$on(OKRsEventType.OKRS_GOALS_CHANGED, (evt, args: GoalsChangedEventArgs) => callback(args));
  }
}

import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { IFeedEntryReaction } from "@gtmhub/feed/models";
import { getCurrentUserId } from "@gtmhub/users";
import { AggregatedReaction, CreateReactionDTO, GetReactionsParams, Reaction } from "@webapp/comments";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { ReactionsApiService } from "./reactions-api.service";
import { ReactionsState } from "./reactions-state.service";

@Injectable({
  providedIn: "root",
})
export class ReactionsFacade extends BaseFacade<Reaction, CreateReactionDTO, ReactionsState, ReactionsApiService> {
  constructor(state: ReactionsState, api: ReactionsApiService) {
    super(state, api);
  }

  public getReactions$(getReactionsParams: GetReactionsParams): Observable<Reaction[]> {
    const requestConfig = {
      ...new RequestConfig(),
      url: this.apiService.getReactionsEndpoint(),
      queryParams: {
        targetIds: getReactionsParams.targetIds,
      },
    };

    return this.apiService.getAll$(null, requestConfig).pipe(map(({ items }) => items));
  }

  public createReaction$(reaction: CreateReactionDTO, metadata?: { [key: string]: string | boolean }): Observable<Reaction> {
    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    };

    return this.apiService.post$(reaction, requestConfig);
  }

  public deleteReaction$(reactionName: string, targetId: string, metadata?: { [key: string]: string | boolean }): Observable<void> {
    const config = {
      ...new RequestConfig(),
      url: this.apiService.getDeleteReactionEndpoint(reactionName, targetId),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    };

    return this.apiService.delete$(reactionName, config);
  }

  public onReactionCreated(reactions: AggregatedReaction[] | IFeedEntryReaction[], newReaction: Reaction): AggregatedReaction[] | IFeedEntryReaction[] {
    const reactionsArray = reactions ? [...reactions] : [];
    const foundReaction = reactionsArray.find((reaction) => reaction.name === newReaction.name);

    if (foundReaction) {
      foundReaction.currentUserReacted = true;
      foundReaction.userIds.push(newReaction.userId);
    } else {
      const { userId, name, emoji } = { ...newReaction };

      const reactionToPush: IFeedEntryReaction | AggregatedReaction = {
        name,
        emoji,
        userIds: [userId],
        currentUserReacted: true,
      };

      reactionsArray.push(reactionToPush);
    }

    return reactionsArray;
  }

  public onReactionDeleted<TypeReaction extends AggregatedReaction | IFeedEntryReaction>(reactions: TypeReaction[], deletedReaction: AggregatedReaction): TypeReaction[] {
    let reactionsArray = reactions ? [...reactions] : [];
    const foundReaction = reactionsArray.find((reaction) => reaction.name === deletedReaction.name);

    if (foundReaction) {
      foundReaction.currentUserReacted = false;
      foundReaction.userIds = foundReaction.userIds.filter((userId) => userId !== getCurrentUserId());

      if (!foundReaction.userIds.length) {
        reactionsArray = reactionsArray.filter((currentReaction) => currentReaction.name !== deletedReaction.name);
      }
    }

    return reactionsArray;
  }

  public postWhiteboardReaction$(reaction: CreateReactionDTO, metadata?: { [key: string]: string | boolean }): Observable<Reaction> {
    const requestConfig = {
      ...new RequestConfig(),
      url: this.apiService.postWhiteboardReactionEndpoint(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    };

    return this.apiService.post$(reaction, requestConfig);
  }

  public deleteWhiteboardReaction$(reactionName: string, targetId: string, metadata?: { [key: string]: string | boolean }): Observable<void> {
    const config = {
      ...new RequestConfig(),
      url: this.apiService.deleteWhiteboardReactionEndpoint(reactionName, targetId),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    };

    return this.apiService.delete$(reactionName, config);
  }
}

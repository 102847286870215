import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, switchMap } from "rxjs";
import { getCurrentUserId } from "@gtmhub/users";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";
import { CurrentUserRepository } from "@webapp/users";
import { GranularCommentsAndReactionsPermissionsService } from "./granular-comments-and-reactions-permissions.service";

type NegateParam = { negate: boolean | undefined };

@Injectable({ providedIn: "root" })
export class CommentsAndReactionsPermissionsService {
  constructor(
    private granularCommentsAndReactionsPermissionsService: GranularCommentsAndReactionsPermissionsService,
    private permissionsFacadeWithFeatureFlag: PermissionsFacadeWithFeatureFlag,
    private currentUserRepository: CurrentUserRepository
  ) {}

  public hasPermissionToCreateCommentsAndReactions$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return this.granularCommentsAndReactionsPermissionsService
      .hasPermissionToManageOwnCommentsAndReactions$()
      .pipe(switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate })));
  }

  public hasPermissionToEditComment$({ commentAuthorId }: { commentAuthorId: string }, { negate }: NegateParam = { negate: false }): Observable<boolean> {
    const isCurrentUserCommentAuthor = commentAuthorId === getCurrentUserId();

    return combineLatest({
      hasPermissionToManageOwnCommentsAndReactions: this.granularCommentsAndReactionsPermissionsService.hasPermissionToManageOwnCommentsAndReactions$(),
      hasPermissionToEditThisComment: of(isCurrentUserCommentAuthor),
    }).pipe(
      map(
        ({ hasPermissionToManageOwnCommentsAndReactions, hasPermissionToEditThisComment }) =>
          hasPermissionToManageOwnCommentsAndReactions && hasPermissionToEditThisComment
      ),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToDeleteComment$({ commentAuthorId }: { commentAuthorId: string }, { negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest({
      hasPermissionToDeleteAnyComment: this.granularCommentsAndReactionsPermissionsService.hasPermissionToDeleteAnyComment$(),
      hasPermissionToDeleteThisComment: this.hasPermissionToDeleteThisComment$({ commentAuthorId }),
    }).pipe(
      map(({ hasPermissionToDeleteAnyComment, hasPermissionToDeleteThisComment }) => hasPermissionToDeleteAnyComment || hasPermissionToDeleteThisComment),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  private hasPermissionToDeleteThisComment$({ commentAuthorId }: { commentAuthorId: string }): Observable<boolean> {
    const isCurrentUserAdmin = this.currentUserRepository.userHasRole("admin");
    const isCurrentUserCommentAuthor = commentAuthorId === getCurrentUserId();

    return this.granularCommentsAndReactionsPermissionsService.hasPermissionToManageOwnCommentsAndReactions$().pipe(
      switchMap((hasPermissionToManageOwnCommentsAndReactions) => {
        const hasPermissionToDeleteOwnComment = hasPermissionToManageOwnCommentsAndReactions && isCurrentUserCommentAuthor;
        return this.permissionsFacadeWithFeatureFlag.hasPermissionWithFeatureFlag$(
          FeatureFlag.ManageCommentsAndReactionsGranularPermissions,
          hasPermissionToDeleteOwnComment,
          isCurrentUserAdmin || hasPermissionToDeleteOwnComment
        );
      })
    );
  }

  private negateIfNecessary$({ hasPermission, negate }: { hasPermission: boolean; negate: boolean }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;
    return of(hasPermission !== shouldNegate);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CurrentUserRepository } from "@webapp/users";

@Component({
  selector: "show-comments-button",
  templateUrl: "./show-comments-button.component.html",
  styleUrls: ["./show-comments-button.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.border-left]": "showBorderOnLeftSide",
  },
})
export class ShowCommentsButtonComponent {
  @Input()
  public avatarPreviewNumber = 2;

  @Input()
  public commentsCount = 0;

  @Input()
  public userIds: string[];

  @Input()
  public onPageUsed: "feed" | "metric" | "thread" | "announcements";

  @Input()
  public showBorderOnLeftSide = false;

  @Output()
  public readonly showCommentsButtonClick: EventEmitter<void> = new EventEmitter();

  public get isCurrentUserViewOnly(): boolean {
    return this.currentUserRepository.userHasRole("view-only");
  }

  constructor(private currentUserRepository: CurrentUserRepository) {}

  public handleEnter(event): void {
    if (event.keyCode === 13) {
      this.showCommentsButtonClick.emit();
    }
  }
}

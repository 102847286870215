import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, tap } from "rxjs";
import { ICheckinComment, IGoal } from "@gtmhub/goals/models";
import { OKRsEventType } from "@gtmhub/okrs/events";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { IBulkDeleteMetricsResponse } from "@webapp/okrs/models/okrs-response.models";
import { metricProperties } from "@webapp/okrs/utils/utils";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";
import { Session } from "@webapp/sessions/models/sessions.model";
import { BulkTagResponse, Tag } from "@webapp/tags/models/tag.model";
import { EditMetricUpdateData, ICheckin, Metric, MetricDTO, MetricFormEventType, MetricFormState, MetricMilestoneEventType, MetricType } from "../models/metric.models";
import { MetricsApiService } from "./metrics-api.service";
import { MetricsState } from "./metrics-state.service";

@Injectable({
  providedIn: "root",
})
export class MetricsFacade extends BaseFacade<Metric, MetricDTO, MetricsState, MetricsApiService> {
  constructor(
    state: MetricsState,
    api: MetricsApiService,
    private broadcastService: BroadcastService,
    private analyticsService: AnalyticsService
  ) {
    super(state, api);
  }

  public getMetric$(metricId: string, fields: string[] = metricProperties, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Metric> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.getAll$(
      { filter: { _id: metricId }, fields: fields },
      {
        ...new RequestConfig(),
        ...context,
      }
    ).pipe(
      map((collection) => {
        if (collection.items?.length) {
          const metricData = collection.items[0];

          return this.convertMetricMilestones(metricData);
        }
        return null;
      })
    );
  }

  public getMetricsV2$(filter?: RequestPaging, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<ICollection<Metric>> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService.getAll$<ICollection<Metric>>(filter, { ...new RequestConfig(), ...context }).pipe(
      map((collection) => {
        if (collection.items?.length) {
          collection.items = collection.items.map((metric) => this.convertMetricMilestones(metric));
        }
        return collection;
      })
    );
  }

  public createMetric$(metric: Partial<Metric>, additionalGtmhubParams?: PluginGtmhubAdditionalParams | Record<string, unknown>): Observable<Metric> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService
      .post$(metric, {
        ...new RequestConfig(),
        ...context,
      })
      .pipe(
        tap((newMetric) => {
          const args = { reason: "metricCreated", detail: { goalId: newMetric.goalId, metric: newMetric } };

          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public patchMetric$(metric: Partial<Metric>, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Metric> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService
      .patch$<Metric>(metric.id, metric, {
        ...new RequestConfig(),
        ...context,
      })
      .pipe(
        tap((patchedMetric) => {
          const args = {
            reason: "metricPatched",
            detail: {
              goalId: patchedMetric.goalId,
              metric: patchedMetric,
              patch: metric,
            },
          };

          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public patchMetricComment$(checkinId: string, payload: ICheckinComment): Observable<ICheckin> {
    const gtmhubAdditionalParams = {
      hasGif: !!payload.gif?.id,
    };
    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };
    return this.patch$(checkinId, payload, { ...requestConfig, url: this.apiService.getUpdateMetricCheckinEndpoint(checkinId) });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public patchMetricUpdate$(checkinId: string, payload: EditMetricUpdateData): Observable<ICheckin> {
    const gtmhubAdditionalParams = {
      hasGif: !!payload.gif?.id,
    };
    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };
    return this.patch$(checkinId, payload, { ...requestConfig, url: this.apiService.getUpdateMetricCheckinEndpoint(checkinId) });
  }

  public deleteMetric$(goalId: string, metricId: string, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<void> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService
      .delete$<void>(metricId, {
        ...new RequestConfig(),
        ...context,
      })
      .pipe(
        tap(() => {
          const args = {
            reason: "metricDeleted",
            detail: {
              goalId,
              metricId,
            },
          };

          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public deleteSnapshot$(goalId: string, metricId: string, snapshotId: string): Observable<void> {
    return this.apiService
      .delete$<void>(snapshotId, {
        ...new RequestConfig(),
        url: this.apiService.getMetricSnapshotDeleteEndpoint(snapshotId),
      })
      .pipe(
        tap(() => {
          const args = {
            reason: "metricSnapshotDeleted",
            detail: {
              goalId,
              metricId,
              snapshotId,
            },
          };

          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public checkin$(metric: Partial<Metric>, checkin: ICheckin, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<ICheckin> {
    const params = {
      ...additionalGtmhubParams,
      hasGif: !!checkin.gif?.id,
    };

    const context = { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, params) };

    return this.apiService
      .post$<ICheckin>(checkin, {
        ...new RequestConfig(),
        ...context,
        url: this.apiService.getMetricSnapshotPostEndpoint(metric.id),
      })
      .pipe(
        tap((newCheckin) => {
          const args = {
            reason: "metricCheckin",
            detail: {
              goalId: metric.goalId,
              metricId: metric.id,
              checkin: newCheckin,
            },
          };

          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public trackMetricForm(event: MetricFormEventType, metric: Partial<Metric>): void {
    const meta = {
      type: metric && metric.id ? MetricFormState.EDIT : MetricFormState.CREATE,
      metric_type: metric && metric.insightName !== "" ? MetricType.DYNAMIC : MetricType.MANUAL,
    };
    this.analyticsService.track(event, meta);
  }

  public publishCheckin({ metric, goal, session }: { metric: Metric; goal: IGoal; session: Session }): void {
    const checkin: ICheckin = {
      checkInDate: new Date().toISOString(),
      actual: metric.actual,
      confidence: metric.confidence?.value,
      customFields: metric.customFields,
    };
    // Should not add this.destroyService here, because the request can be canceled before it is completed
    this.checkin$({ id: metric.id }, checkin).subscribe(() => {
      this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
        reason: "metricSnapshotCreated",
        detail: {
          checkin: checkin,
          metric: metric,
          goal: goal,
          session: session,
        },
      });
    });
  }

  public getMetricByCheckin$(checkinId: string): Observable<Metric> {
    return this.get$(null, { ...new RequestConfig(), url: this.apiService.getMetricCheckinEndpoint(checkinId) });
  }

  public trackMetricMilestone(event: MetricMilestoneEventType, args: { metric?: Metric; enabled?: boolean }): void {
    switch (event) {
      case MetricMilestoneEventType.KR_MILESTONES_ENABLED:
        this.analyticsService.track(event, { enabled: args.enabled });
        break;
      case MetricMilestoneEventType.KR_MILESTONES_CREATED:
        this.trackMetricMilestones(event, args.metric);
        break;
      case MetricMilestoneEventType.KR_MILESTONES_DELETED:
        this.trackMetricMilestones(event, args.metric);
        break;
    }
  }

  private trackMetricMilestones(event: MetricMilestoneEventType, metric: Metric): void {
    this.analyticsService.track(event, {
      milestones_per_metric: metric.milestones?.length && 0,
    });
  }

  public addTag$(metricId: string, title: string): Observable<Tag> {
    return this.apiService
      .post$<Tag>(
        { title },
        {
          ...new RequestConfig(),
          url: this.apiService.getAddMetricTagsEndpoint(metricId),
        }
      )
      .pipe(
        tap((tag: Tag) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagAdded",
            detail: {
              metricId,
              tag,
            },
          });
        })
      );
  }

  public addTags$(metricId: string, titles: string[]): Observable<Tag> {
    return this.apiService
      .post$<Tag>(
        { titles },
        {
          ...new RequestConfig(),
          url: this.apiService.getAddMetricTagsEndpoint(metricId),
        }
      )
      .pipe(
        tap((tag: Tag) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagsAdded",
            detail: {
              metricId,
              tags: tag.items,
            },
          });
        })
      );
  }

  public bulkPatchMetricsTags$(metricIds: string[], tagsToAdd?: string[], tagsToRemove?: string[]): Observable<BulkTagResponse> {
    return this.apiService
      .patch$<BulkTagResponse>(
        "",
        { ids: metricIds, tagsToAdd: { titles: tagsToAdd }, tagsToRemove },
        {
          ...new RequestConfig(),
          url: this.apiService.getPatchBulkTagsOperationEndpoint(),
        }
      )
      .pipe(
        tap((response: BulkTagResponse) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagsUpdated",
            detail: {
              metricIds: response.updatedMetricsIds,
            },
          });
        })
      );
  }

  public removeTag$(metricId: string, tag: Tag): Observable<void> {
    return this.apiService
      .delete$<void>(null, {
        ...new RequestConfig(),
        url: this.apiService.getRemoveMetricTagsByTitleEndpoint(metricId, [tag.title]),
      })
      .pipe(
        tap(() => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagRemoved",
            detail: {
              metricId,
              tag,
            },
          });
        })
      );
  }

  private convertMetricMilestones(metric: Metric): Metric {
    const milestones = metric.milestones || [];
    const isMilestonesIncluded = metric.milestones && metric.milestones.length > 0;

    if (!isMilestonesIncluded) {
      return metric;
    }

    milestones.forEach((milestone) => {
      milestone.date = new Date(milestone.date);
    });

    return metric;
  }

  public deleteMetrics$(metricIds: string[]): Observable<IBulkDeleteMetricsResponse> {
    return this.deleteMany$(metricIds, {
      ...new RequestConfig(),
      url: this.apiService.getBulkDeleteMetricsEndpoint(),
    });
  }
}

import { IPromise, IQService, Injectable, auto, noop } from "angular";
import { Ng2StateDeclaration } from "@uirouter/angular";
import { Ng1StateDeclaration, StateService } from "@uirouter/angularjs";
import { EditionFeature } from "@webapp/accounts/models/edition.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IGtmhubRootScopeService } from "./models";

export type PromiseInjectable = Injectable<(...args: unknown[]) => IPromise<void>>;

export const featureToggleEnabled = (featureKey: string, redirectState?: string): PromiseInjectable => [
  "$q",
  "$injector",
  "$state",
  ($q: IQService, $injector: auto.IInjectorService, $state: StateService): IPromise<void> => {
    return $q.when($injector.get<FeatureTogglesFacade>("FeatureTogglesFacade").isFeatureAvailable(featureKey)).then((enabled) => {
      if (!enabled) {
        $state.go(redirectState || "default");
        return $q.reject(`feature ${featureKey} is not enabled`);
      }
    });
  },
];

export const featureToggleDisabled = (featureKey: string): PromiseInjectable => [
  "$q",
  "$injector",
  "$state",
  ($q: IQService, $injector: auto.IInjectorService, $state: StateService): IPromise<void> => {
    return $q.when($injector.get<FeatureTogglesFacade>("FeatureTogglesFacade").isFeatureAvailable(featureKey)).then((enabled) => {
      if (enabled) {
        $state.go("default");
        return $q.reject(`feature ${featureKey} should be disabled`);
      }
    });
  },
];

export const featureToggleDisabledWithRedirect = (featureKey: string, redirectState: string): PromiseInjectable => [
  "$q",
  "$injector",
  "$state",
  ($q: IQService, $injector: auto.IInjectorService, $state: StateService): IPromise<void> => {
    return $q.when($injector.get<FeatureTogglesFacade>("FeatureTogglesFacade").isFeatureAvailable(featureKey)).then((enabled) => {
      if (!enabled) {
        $state.go(redirectState);
        return $q.resolve();
      }
    });
  },
];

export const featuresAvailable = (...featureKeys: string[]): PromiseInjectable => [
  "$q",
  "$injector",
  "$state",
  ($q: IQService, $injector: auto.IInjectorService, $state: StateService): IPromise<void> => {
    return $q
      .all(
        featureKeys.reduce((featureMap, featureKey) => {
          featureMap[featureKey] = $q.when($injector.get<FeatureTogglesFacade>("FeatureTogglesFacade").isFeatureAvailable(featureKey));
          return featureMap;
        }, {})
      )
      .then((featureMap) => {
        let disabledFeature = "";

        Object.keys(featureMap).some((featureKey) => {
          disabledFeature = featureMap[featureKey] ? "" : featureKey;
          return !featureMap[featureKey];
        });

        if (disabledFeature) {
          $state.go("default");
          return $q.reject(`feature ${disabledFeature} is not enabled`);
        }
      });
  },
];

export const featureAvailable = (feature: EditionFeature): PromiseInjectable => [
  "$q",
  "$rootScope",
  ($q: IQService, $rootScope: IGtmhubRootScopeService): IPromise<void> => {
    return $rootScope.featureAvailable(feature) ? $q.resolve() : $q.reject(`feature ${feature} not available`);
  },
];

export const resolveStateWhen = (...funcs: PromiseInjectable[]): PromiseInjectable => [
  "$q",
  "$injector",
  ($q: IQService, $injector: auto.IInjectorService): IPromise<void> => {
    const promises = funcs.map((func) => $injector.invoke(func));

    return $q.all(promises).then(noop);
  },
];

const attachStateDependency = <T extends Ng1StateDeclaration | Ng2StateDeclaration>(state: T, ...guards: PromiseInjectable[]): T => {
  if (!state.resolve) {
    state.resolve = {} as Ng1StateDeclaration["resolve"] | Ng2StateDeclaration["resolve"];
  }

  state.resolve["_stateDependency"] = resolveStateWhen(...guards);

  return state;
};

export const attachNg1StateDependency = (state: Ng1StateDeclaration, ...guards: PromiseInjectable[]): Ng1StateDeclaration => {
  return attachStateDependency<Ng1StateDeclaration>(state, ...guards);
};

export const attachNg2StateDependency = (state: Ng2StateDeclaration, ...guards: PromiseInjectable[]): Ng2StateDeclaration => {
  return attachStateDependency<Ng2StateDeclaration>(state, ...guards);
};

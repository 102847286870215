import { UIRouterGlobals } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { take, zip } from "rxjs";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { CreateOkrFormModalService } from "@webapp/okrs/components/create-okr-form/create-okr-form-modal.service";
import { GuidedOKRsComponent, GuidedOKRsModalData } from "@webapp/platform-intelligence/guided-okrs/guided-okrs.component";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Session } from "@webapp/sessions/models/sessions.model";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { WhiteboardPermissionsService } from "@webapp/whiteboards/services/whiteboard-permissions.service";

/**
 * A component for opening dropdown with three choices - Suggest OKRs | Create OKR | Choose from a template | brainstorm and ideate
 * @example <create-okr-dropdown-button [disabled]="boolean" [hideCreateOKR]="boolean"></create-okr-dropdown-button>
 */
@Component({
  selector: "create-okr-dropdown-button",
  templateUrl: "./create-okr-dropdown-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateOkrDropdownButtonComponent implements OnInit {
  /**
   * @param disabled boolean
   */
  @Input() public disabled: boolean;
  @Input() public hideCreateOkr = false;

  private canCreateWhiteboards: boolean;
  private isGuidedOKRsCreationEnabled: boolean;
  private isMarketplaceEnabled: boolean;

  public menuItems: DropdownMenuItem[];
  public dropdownVisible: boolean;

  constructor(
    private routerGlobals: UIRouterGlobals,
    private uiModalService: UiModalService,
    private createOkrFormModalService: CreateOkrFormModalService,
    private defaultSessionResolverService: DefaultSessionResolverService,
    private piFeatureVisibilityFacade: PiFeatureVisibilityFacade,
    private editionFeatureService: EditionFeatureService,
    private cdr: ChangeDetectorRef,
    private whiteboardPermissionsService: WhiteboardPermissionsService
  ) {}

  public ngOnInit(): void {
    zip([
      this.whiteboardPermissionsService.hasPermissionToCreate$(),
      this.piFeatureVisibilityFacade.isGuidedOKRsCreationVisible$(),
      this.editionFeatureService.hasFeature$("marketplace"),
    ])
      .pipe(take(1))
      .subscribe(([canCreateWhiteboards, isGuidedOKRsCreationEnabled, isMarketplaceEnabled]) => {
        this.canCreateWhiteboards = canCreateWhiteboards;
        this.isGuidedOKRsCreationEnabled = isGuidedOKRsCreationEnabled;
        this.isMarketplaceEnabled = isMarketplaceEnabled;
        this.setOptions();
      });
  }

  public dropdownToggled(value: boolean): void {
    this.dropdownVisible = value;
  }

  private setOptions(): void {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    let suggestOKRs: DropdownMenuItem, createOkr: DropdownMenuItem, brainstormAndIdeate: DropdownMenuItem, chooseFromTemplate: DropdownMenuItem;

    if (this.isGuidedOKRsCreationEnabled) {
      suggestOKRs = menuItemBuilder
        .setKey("suggest_okrs")
        .setDescriptionKey("draft_okrs_personalized_suggestions")
        .setAction({
          handler: () => this.openQuantiveSuggestionsWizard(),
        })
        .setUiType({ uiType: "uiIcon", iconType: "artificial-intelligence", iconTheme: "outline" })
        .setClasses(["text-color-gray-1"])
        .setToBeEndOfSection()
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .setTrack("Button Clicked")
        .setTrackMetaData({ button_name: "suggest_okrs" })
        .build();
    }

    if (!this.hideCreateOkr) {
      createOkr = menuItemBuilder
        .setKey("create_okr")
        .setDescriptionKey("directly_add_your_okrs")
        .setAction({
          handler: () => this.openCreateGoalForm(),
        })
        .setUiType({ uiType: "uiIcon", iconType: "objective", iconTheme: "outline" })
        .setToBeEndOfSection()
        .build();
    }

    if (this.canCreateWhiteboards) {
      brainstormAndIdeate = menuItemBuilder
        .setKey("brainstorm_and_ideate")
        .setDescriptionKey("use_a_whiteboard_to_draft_okrs")
        .setAction({
          state: "gtmhub.whiteboards",
        })
        .setUiType({ uiType: "uiIcon", iconType: "whiteboards", iconTheme: "outline" })
        .setToBeEndOfSection()
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .setTrack("Button Clicked")
        .setTrackMetaData({ button_name: "brainstorm_and_ideate" })
        .build();
    }

    if (this.isMarketplaceEnabled) {
      chooseFromTemplate = menuItemBuilder
        .setKey("choose_from_template")
        .setDescriptionKey("get_inspiration_marketplace")
        .setAction({
          href: "/marketplace/okrs-examples",
        })
        .setUiType({ uiType: "uiIcon", iconType: "add-from-marketplace", iconTheme: "outline" })
        .setToBeEndOfSection()
        .setExternalLink()
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .build();
    }

    this.menuItems = [suggestOKRs, createOkr, chooseFromTemplate, brainstormAndIdeate].filter(Boolean);
    this.cdr.markForCheck();
  }

  private openCreateGoalForm(): void {
    this.createOkrFormModalService.openFormModal({
      sessionId: this.routerGlobals.params["planningSessionId"],
      flowName: PiTrackingFlowNameEnum.CreateObjectiveFormTopNav,
    });
  }

  private openQuantiveSuggestionsWizard(): void {
    const querySessionId = this.routerGlobals.params["planningSessionId"];
    const state = reduxStoreContainer.reduxStore.getState<ISessionsStoreState>();
    const sessions: Session[] = state.sessions.items;

    if (querySessionId) {
      this.uiModalService.create<GuidedOKRsComponent, GuidedOKRsModalData>({
        uiContent: GuidedOKRsComponent,
        uiWrapClassName: "guided-okrs-modal-wrapper",
        uiWidth: "100%",
        uiFooter: null,
        uiClosable: false,
        uiKeyboard: false,
        uiData: {
          flowName: PiTrackingFlowNameEnum.GuidedOkrsCreationCreateOkrTopNav,
          currentSession: sessions.find((s) => s.id === querySessionId),
          readOnlySession: true,
        },
      });
      return;
    }

    const defaultSession = this.defaultSessionResolverService.getDefaultSession(sessions);
    this.uiModalService.create<GuidedOKRsComponent, GuidedOKRsModalData>({
      uiContent: GuidedOKRsComponent,
      uiWrapClassName: "guided-okrs-modal-wrapper",
      uiWidth: "100%",
      uiFooter: null,
      uiClosable: false,
      uiKeyboard: false,
      uiData: {
        flowName: PiTrackingFlowNameEnum.GuidedOkrsCreationCreateOkrTopNav,
        currentSession: defaultSession,
      },
    });
  }
}

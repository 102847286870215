import { Directionality } from "@angular/cdk/bidi";
import { ComponentType, Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, TemplatePortal } from "@angular/cdk/portal";
import { Injectable, Injector, OnDestroy, Optional, SkipSelf, TemplateRef } from "@angular/core";
import { uiToNz } from "@quantive/ui-kit/core";
import { NzConfigService } from "ng-zorro-antd/core/config";
import { warn } from "ng-zorro-antd/core/logger";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { isNotNil } from "ng-zorro-antd/core/util";
import { BaseModalContainerComponent, MODAL_MASK_CLASS_NAME, ModalOptions, NZ_CONFIG_MODULE_NAME } from "ng-zorro-antd/modal";
import { Subject } from "rxjs";
import { UiModalRef } from "../abstracts/modal-ref";
import { UiModalConfirmContainerComponent } from "../components/modal-confirm-container/modal-confirm-container.component";
import { UiModalContainerComponent } from "../components/modal-container/modal-container.component";
import { CustomModalOptions, UI_MODAL_DATA, UI_MODAL_INDEX, UiConfirmType, UiModalOptions } from "../modal.models";
import { getValueWithConfig } from "../utils/modal.utils";

type ContentType<T> = ComponentType<T> | TemplateRef<T> | string;

export const CONFIRM_MODAL_ICONS: Record<UiConfirmType, string> = {
  info: "info",
  success: "check",
  error: "failed",
  warning: "warn",
  confirm: "help",
};

@Injectable({ providedIn: "root" })
export class UiModalService implements OnDestroy {
  private openModalsAtThisLevel: UiModalRef[] = [];
  private readonly afterCloseAtThisLevel = new Subject<UiModalRef>();

  get openModals(): UiModalRef[] {
    return this.parentModal ? this.parentModal.openModals : this.openModalsAtThisLevel;
  }

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private nzConfigService: NzConfigService,
    @Optional() @SkipSelf() private parentModal: UiModalService,
    @Optional() private directionality: Directionality
  ) {}

  public create<T, D = NzSafeAny, R = NzSafeAny>(config: UiModalOptions<T, D, R>): UiModalRef<T, R> {
    return this.open<T, D, R>(config.uiContent, config);
  }

  /**
   * This method is only added to work in Angular.js as a downgraded service,
   * since there is an issue accessing getters in a proxy.
   *
   * @deprecated Use openModals directly
   */
  public getOpenModals(): UiModalRef[] {
    return [...this.openModals];
  }

  public closeAll(): void {
    this.closeModals(this.openModals);
  }

  public confirm<T, D>(options: UiModalOptions<T, D> = {}, confirmType: UiConfirmType = "confirm"): UiModalRef<T> {
    if ("uiFooter" in options) {
      warn(`The Confirm-Modal doesn't support "uiFooter", this property will be ignored.`);
    }
    if (!("uiWidth" in options)) {
      options.uiWidth = 500;
    }
    if (!("uiMaskClosable" in options)) {
      options.uiMaskClosable = false;
    }
    if (!("uiIconType" in options)) {
      options.uiIconType = CONFIRM_MODAL_ICONS[confirmType];
    }
    if (!("uiAutofocus" in options)) {
      options.uiAutofocus = "auto";
    }

    options.uiModalType = "confirm";
    options.uiClassName = `ant-modal-confirm ant-modal-confirm-${confirmType} ${options.uiClassName || ""}`;

    return this.create<T, D>(options);
  }

  public info<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    return this.confirmFactory(options, "info");
  }

  public success<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    return this.confirmFactory(options, "success");
  }

  public error<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    return this.confirmFactory(options, "error");
  }

  public warning<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    return this.confirmFactory(options, "warning");
  }

  public afterClose$(): Subject<UiModalRef> {
    const parent = this.parentModal;
    return parent ? parent.afterClose$() : this.afterCloseAtThisLevel;
  }

  public ngOnDestroy(): void {
    this.closeModals(this.openModalsAtThisLevel);
    this.afterCloseAtThisLevel.complete();
  }

  private open<T, D, R>(componentOrTemplateRef: ContentType<T>, config: UiModalOptions<T, D, R>): UiModalRef<T, R> {
    const configMerged = uiToNz({ ...new UiModalOptions(), ...config } as CustomModalOptions);
    const overlayRef = this.createOverlay(configMerged);
    const modalContainer = this.attachModalContainer(overlayRef, configMerged);
    const modalRef = this.attachModalContent<T, D, R>(componentOrTemplateRef, modalContainer, overlayRef, configMerged);
    modalContainer.modalRef = modalRef;

    this.openModals.push(modalRef);
    modalRef.afterClose.subscribe(() => this.removeOpenModal(modalRef));

    return modalRef;
  }

  private removeOpenModal(modalRef: UiModalRef): void {
    const index = this.openModals.indexOf(modalRef);
    if (index > -1) {
      this.openModals.splice(index, 1);

      if (!this.openModals.length) {
        this.afterCloseAtThisLevel.next(modalRef);
      }
    }
  }

  private closeModals(dialogs: UiModalRef[]): void {
    let i = dialogs.length;
    while (i--) {
      dialogs[i].close();
      if (!this.openModals.length) {
        this.afterCloseAtThisLevel.next(dialogs[i]);
      }
    }
  }

  /**
   * @override Differs from the original
   */
  private createOverlay(config: ModalOptions): OverlayRef {
    const globalConfig: NzSafeAny = this.nzConfigService.getConfigForComponent(NZ_CONFIG_MODULE_NAME) || {};
    const hasMask = getValueWithConfig(config.nzMask, globalConfig.nzMask, true);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: hasMask,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global(),
      disposeOnNavigation: getValueWithConfig(config.nzCloseOnNavigation, globalConfig.nzCloseOnNavigation, true),
      direction: getValueWithConfig(config.nzDirection, globalConfig.nzDirection, this.directionality.value),
    });
    if (hasMask) {
      overlayConfig.backdropClass = MODAL_MASK_CLASS_NAME;
    }

    return this.overlay.create(overlayConfig);
  }

  private attachModalContainer(overlayRef: OverlayRef, config: ModalOptions): BaseModalContainerComponent {
    const userInjector = config.nzViewContainerRef?.injector;
    const injector = Injector.create({
      parent: userInjector || this.injector,
      providers: [
        { provide: OverlayRef, useValue: overlayRef },
        { provide: ModalOptions, useValue: config },
        { provide: UI_MODAL_INDEX, useValue: this.openModals.length },
      ],
    });

    const ContainerComponent =
      config.nzModalType === "confirm"
        ? // If the mode is `confirm`, use `NzModalConfirmContainerComponent`
          UiModalConfirmContainerComponent
        : // If the mode is not `confirm`, use `NzModalContainerComponent`
          UiModalContainerComponent;

    const containerPortal = new ComponentPortal<BaseModalContainerComponent>(ContainerComponent, config.nzViewContainerRef, injector);
    const containerRef = overlayRef.attach<BaseModalContainerComponent>(containerPortal);

    return containerRef.instance;
  }

  private attachModalContent<T, D, R>(
    componentOrTemplateRef: ContentType<T>,
    modalContainer: BaseModalContainerComponent,
    overlayRef: OverlayRef,
    config: ModalOptions<T, D, R>
  ): UiModalRef<T, R> {
    const modalRef = new UiModalRef<T, R>(overlayRef, config, modalContainer);

    if (componentOrTemplateRef instanceof TemplateRef) {
      modalContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, {
          $implicit: config.nzData,
          modalRef,
        } as NzSafeAny)
      );
    } else if (isNotNil(componentOrTemplateRef) && typeof componentOrTemplateRef !== "string") {
      const injector = this.createInjector<T, D, R>(modalRef, config);
      const contentRef = modalContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, config.nzViewContainerRef, injector));
      modalRef.componentInstance = contentRef.instance;
    } else {
      modalContainer.attachStringContent();
    }
    return modalRef;
  }

  private createInjector<T, D, R>(modalRef: UiModalRef<T, R>, config: CustomModalOptions<T, D, R>): Injector {
    const userInjector = config.nzViewContainerRef?.injector;

    return Injector.create({
      parent: userInjector || this.injector,
      providers: [
        { provide: UiModalRef, useValue: modalRef },
        { provide: ModalOptions, useValue: config },
        { provide: UI_MODAL_DATA, useValue: config.nzData },
        ...(config.nzProviders || []),
      ],
    });
  }

  private confirmFactory<T, D>(options: UiModalOptions<T, D> = {}, confirmType: UiConfirmType): UiModalRef<T> {
    if (!("uiCancelText" in options)) {
      // Remove the Cancel button if the user not specify a Cancel button
      options.uiCancelText = null;
    }
    return this.confirm<T, D>(options, confirmType);
  }
}

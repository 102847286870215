import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommentsAndReactionsPermissionsService } from "@webapp/comments/services/comments-and-reactions-permissions.service";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";

@Component({
  selector: "comment-options-dropdown",
  templateUrl: "./comment-options-dropdown.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentOptionsDropdownComponent implements OnInit {
  @Input()
  public commentAuthorId: string;

  @Input()
  public disabled: boolean;

  @Output()
  public readonly edit: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly delete: EventEmitter<void> = new EventEmitter();

  public menuItems: DropdownMenuItem[] = [];

  public constructor(private commentsAndReactionsPermissionsService: CommentsAndReactionsPermissionsService) {}

  public ngOnInit(): void {
    this.initializeMenuItems();
  }

  private initializeMenuItems(): void {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    this.menuItems.push(
      menuItemBuilder
        .setKey("edit_comment")
        .setAction({ handler: (): void => this.edit.emit() })
        .setUiType({ uiType: "uiIcon", iconType: "edit", iconTheme: "outline" })
        .setIsVisibleCondition(this.commentsAndReactionsPermissionsService.hasPermissionToEditComment$({ commentAuthorId: this.commentAuthorId }))
        .build()
    );

    this.menuItems.push(
      menuItemBuilder
        .setKey("delete_comment")
        .setToBeDestructive()
        .setAction({ handler: (): void => this.delete.emit() })
        .setUiType({ uiType: "uiIcon", iconType: "trash-bin", iconTheme: "outline" })
        .setIsVisibleCondition(this.commentsAndReactionsPermissionsService.hasPermissionToDeleteComment$({ commentAuthorId: this.commentAuthorId }))
        .build()
    );
  }
}

import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";

@Injectable({ providedIn: "root" })
export class GranularPIFeaturePermissionsFacade {
  constructor(private permissionsFacade: PermissionsFacadeWithFeatureFlag) {}
  public hasPermissionToView$(params: { negate: boolean | undefined } = { negate: false }): Observable<boolean> {
    return this.hasPermissionTo$({
      negate: params.negate,
      permissionWhenFlagIsOn: "AccessPlatformIntelligence",
      permissionWhenFlagIsOff: true,
    });
  }

  private hasPermissionTo$({
    negate,
    permissionWhenFlagIsOn,
    permissionWhenFlagIsOff,
  }: {
    negate: boolean | undefined;
    permissionWhenFlagIsOn: Permission | boolean;
    permissionWhenFlagIsOff: Permission | boolean;
  }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;

    return this.permissionsFacade.hasPermissionWithFeatureFlag$(FeatureFlag.AccessPlatformIntelligencePermission, permissionWhenFlagIsOn, permissionWhenFlagIsOff).pipe(
      map((hasPermission) => {
        return hasPermission !== shouldNegate;
      })
    );
  }
}

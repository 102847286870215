<div class="button-container" *ngIf="useSplitButton">
  <button
    #triggerButton
    class="selected-access-type"
    [uiDropdownMenu]="accessDropdown"
    [uiVisible]="showPermissions"
    [attr.aria-expanded]="showPermissions ? true : null"
    [attr.aria-haspopup]="true"
    (uiVisibleChange)="changePermissionsVisibility()"
    uiShape="round"
    uiType="text"
    data-test-id="simple-permissions-button"
    [attr.aria-label]="triggerButtonAriaLabel"
    [attr.aria-description]="triggerButtonAriaDescription"
    ui-dropdown
    uiTrigger="click"
    uiPlacement="bottomRight">
    <i [uiType]="selectedAccessOption.icon" ui-icon uiSize="sm" uiTheme="outline"></i> {{ !compactMode ? (selectedAccessOption?.labelKey | localize) : "" }}
  </button>
  <button class="share-button" ui-button uiShape="round" uiType="text" uiSize="default" (click)="handleShareableLink()"><i ui-icon uiType="link"></i></button>
</div>

<button
  *ngIf="!useSplitButton"
  #triggerButton
  class="selected-access-type"
  [uiDropdownMenu]="accessDropdown"
  [uiVisible]="showPermissions"
  [attr.aria-expanded]="showPermissions ? true : null"
  [attr.aria-haspopup]="true"
  (uiVisibleChange)="changePermissionsVisibility()"
  data-test-id="simple-permissions-button"
  [attr.aria-label]="triggerButtonAriaLabel"
  [attr.aria-description]="triggerButtonAriaDescription"
  ui-dropdown
  uiTrigger="click"
  uiPlacement="bottomRight">
  <i [uiType]="selectedAccessOption.icon" ui-icon uiSize="sm" uiTheme="outline"></i> {{ !compactMode ? (selectedAccessOption?.labelKey | localize) : "" }}
  <i *ngIf="!compactMode" ui-icon uiSize="sm" uiType="chevron-down-small"></i>
</button>
<ui-dropdown-menu #accessDropdown="uiDropdownMenu" tabindex="0">
  <ul #accessDropdownContainer class="access-options-menu" data-test-id="simple-permissions-dropdown" ui-menu>
    <ui-radio-group #radioGroup [(ngModel)]="selectedAccessOption" (ngModelChange)="changeAccessType()" role="radiogroup" aria-label="Select who has access">
      <span class="menu-title">{{ "who_has_access" | localize }}</span>
      <li class="access-option" *ngFor="let option of accessOptions" ui-menu-item>
        <span [uiValue]="option" data-test-id="simple-permission-label" ui-radio><i [uiType]="option.icon" uiSize="sm" ui-icon uiTheme="outline"></i>{{ option.labelKey | localize }}</span>
      </li>
    </ui-radio-group>
    <div class="restricted-access-section" *ngIf="selectedAccessOption && selectedAccessOption.value === accessTypes.restricted">
      <div class="principals" *ngIf="principalsMap && access && access.permissions && access.permissions.length" role="group" aria-label="List of invitees">
        <div #permissionRow [class.principal-owner-row]="ownerId === permission?.principalId" class="principal" *ngFor="let permission of access.permissions; trackBy: trackByPrincipalId">
          <div class="image">
            <ui-assignee-avatar [fromAssigneeId]="permission?.principalId" [uiShowTooltip]="true" uiTooltipPlacement="bottom" uiSize="xs"></ui-assignee-avatar>
          </div>
          <div
            class="name"
            [id]="permission?.principalId"
            [uiTooltipTitle]="principalsMap[permission?.principalId]?.name"
            [uiTooltipVisible]="checkOverflow(permission?.principalId)"
            ui-tooltip
            uiTooltipPlacement="bottom">
            <span *ngIf="permission.principalKind === 'role'">{{ "everyone_in_named_role2" | localize: { name: principalsMap[permission?.principalId]?.name } }}</span>
            <ui-assignee-name *ngIf="permission.principalKind !== 'role'" class="principal-assignee-name" [fromAssigneeId]="permission?.principalId"></ui-assignee-name>
          </div>
          <span class="grant item-owner-label" [attr.aria-label]="getItemOwnerAriaLabel(permission)" tabindex="0" *ngIf="ownerId === permission?.principalId">{{ "owner" | localize }}</span>
          <gh-dropdown
            *ngIf="ownerId !== permission?.principalId"
            class="permission-dropdown-menu-button-container"
            [menuItems]="inviteesAccessDropdownMenuItems"
            [hasCustomButtonContent]="true"
            (selectedItem)="dropdownSelectedChange($event, permission, permissionRow)"
            placement="bottomRight">
            <button
              tabindex="0"
              [attr.aria-label]="getAccessInviteeAriaLabel(permission)"
              uiSize="small"
              aria-haspopup="menu"
              gh-dropdown-custom-button-content
              uiType="text"
              ui-button
              uiTrigger="click"
              uiPlacement="bottomRight">
              {{ (permission.grant.general.includes("update") ? "can_edit" : "can_view") | localize }}
            </button>
          </gh-dropdown>
        </div>
      </div>
      <div class="invite-user-section">
        <span class="invite-user-title">{{ "invite_someone" | localize }}</span>
        <div class="search-holder">
          <simple-search
            #simpleSearch
            [searchCollections]="searchCollections"
            [searchPlaceholder]="'search_for_users_teams_roles' | localize"
            (itemClicked)="addPrincipal($event)"
            activeUsersOnly
            activeTeamsOnly></simple-search>
        </div>
      </div>
    </div>
    <okr-views-copy-link-form class="copy-link-form" *ngIf="shareableLink?.shouldDisplay" [url]="shareableLink.url" (copyLink)="shareableLink.copyLinkHandler()" />
  </ul>
</ui-dropdown-menu>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, distinctUntilChanged, map, merge } from "rxjs";
import { localize } from "@gtmhub/localization";
import { NotificationsInboxService } from "@gtmhub/notifications/controllers/notifications-inbox";
import { INotificationsCountData } from "@gtmhub/sockets";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { IScreen } from "@webapp/navigation/models/shared.models";
import { NotificationsFacade } from "@webapp/notifications/services/notifications/notifications-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { CurrentUserRepository } from "@webapp/users";

@UntilDestroy()
@Component({
  selector: "bottom-section-nav",
  templateUrl: "./bottom-section-nav.component.html",
  styleUrls: ["./bottom-section-nav.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSectionNavComponent implements OnInit {
  @Input() public settingsScreen: IScreen;
  @Input() public activeScreen: string;

  public showMarketplace$: Observable<boolean>;
  public unreadNotificationsCount: number;
  public unreadNotificationsCountAriaLabel: string;
  public showAppsSwitcherMenu = false;

  constructor(
    private analyticsService: AnalyticsService,
    private notificationsInboxService: NotificationsInboxService,
    private currentUserRepository: CurrentUserRepository,
    private notificationsFacade: NotificationsFacade,
    private changeDetector: ChangeDetectorRef,
    private broadcastService: BroadcastService,
    private permissionsFacade: PermissionsFacade,
    private editionFeatureService: EditionFeatureService
  ) {}

  public ngOnInit(): void {
    this.getInitialNotificationsCount();
    this.subscribeToNotificationsCountUpdates();
    this.setMarketplaceVisiblity();
    this.setShowAppsSwitcherButton();
  }

  public openNotificationsModal(): void {
    this.notificationsInboxService.open({}, this.unreadNotificationsCount);
    this.analyticsService.track("Notification Modal Opened", { user_id: getCurrentUserId() });
  }

  public setMarketplaceCookie(): void {
    this.currentUserRepository.setMarketplaceRedirectCookie();
  }

  private setMarketplaceVisiblity(): void {
    this.showMarketplace$ = this.editionFeatureService.hasFeature$("marketplace");
  }

  private getInitialNotificationsCount(): void {
    this.notificationsFacade
      .getUnreadNotificationsCount$()
      .pipe(untilDestroyed(this))
      .subscribe((count) => {
        this.setNotificationsButtonAriaLabel(count);
        this.unreadNotificationsCount = count;
        this.changeDetector.markForCheck();
      });
  }

  private setNotificationsButtonAriaLabel(newNotifications?: number): void {
    this.unreadNotificationsCountAriaLabel = newNotifications
      ? localize("notifications") + " " + localize("new_notifications", { newNotifications })
      : localize("notifications");
  }

  private subscribeToNotificationsCountUpdates(): void {
    merge(
      this.broadcastService.on<number>("newMessagesCount"),
      this.broadcastService.on<INotificationsCountData>("notificationsCount").pipe(map((collection) => collection.totalCount))
    )
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((count: number): void => {
        this.notificationsFacade.updateUnreadNotificationsCount(count);
        this.changeDetector.markForCheck();
      });
  }

  private setShowAppsSwitcherButton(): void {
    this.permissionsFacade
      .getAvailableProducts$()
      .pipe(untilDestroyed(this))
      .subscribe((product) => {
        this.showAppsSwitcherMenu = product?.length > 1;
        this.changeDetector.markForCheck();
      });
  }
}

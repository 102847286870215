import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { take } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { filterActiveAssigneeIds } from "@gtmhub/assignees/redux/assignee-selectors";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { MetricsFacade } from "@webapp/okrs/metrics/services/metrics-facade.service";
import { IPIFeedbackSuggestion } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/models";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { IQuantivePlusMetric } from "@webapp/platform-intelligence/shared/models";
import { PiCardBorderStyle } from "@webapp/platform-intelligence/shared/models/pi-card.models";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { ObjectiveFlowName } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { IQuantivePlusSuggestionStatus } from "../../models";

const getMetricPayload = (
  goal: Partial<Goal>,
  suggestion: IQuantivePlusMetric,
  metricsInstance: PiStateProcessorInstance
): Partial<Metric> & { flowId: string; flowName: ObjectiveFlowName } => {
  const assigneesMap: Record<string, Assignee> = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>().assignees.map;
  const filteredOwnerIds = filterActiveAssigneeIds(goal.ownerIds, assigneesMap);
  return {
    ownerIds: filteredOwnerIds,
    goalId: goal.id,
    manualType: "double",
    targetOperator: suggestion.targetOperator,
    initialValue: Number(suggestion.initialValue),
    target: Number(suggestion.target),
    format: suggestion.format,
    name: suggestion.name,
    description: suggestion.description,
    flowId: metricsInstance.flowId,
    flowName: metricsInstance.flowName as ObjectiveFlowName,
  };
};

@UntilDestroy()
@Component({
  selector: "quantive-plus-metrics",
  templateUrl: "./quantive-plus-metrics.component.html",
  styleUrls: ["./quantive-plus-metrics.component.less"],
})
export class QuantivePlusMetricsComponent implements OnInit {
  @Input() public goal: Goal;
  @Input() public metrics: IQuantivePlusMetric[];
  @Input() public suggestionsStatus: IQuantivePlusSuggestionStatus;
  @Input() public metricsInstance: PiStateProcessorInstance;
  @Input() public iconType: string;
  @Input() public iconTheme: UiThemeType;
  @Input() public borderStyle: PiCardBorderStyle;
  @Input() public isInline = false;
  @Input() public isRemoveBtnAvailable = false;
  @Input() public isRemoveBtnAtHeader = false;

  @Output() public readonly metricCreated = new EventEmitter<{ metric: Metric; quantivePlusSuggestionIndex: number }>();
  @Output() public readonly metricSuggest = new EventEmitter<{ suggestionType: "metric"; suggestionToReplace: IQuantivePlusMetric; suggestionToReplaceIndex: number }>();
  @Output() public readonly metricReject = new EventEmitter<number>();
  @Output() public readonly allMetricsSuggest = new EventEmitter();
  @Output() public readonly goBackToSuggestion = new EventEmitter<{ suggestionIndex: number }>();

  public maxMetricsToSuggest = 3;
  public quantivePlusCardBorderStyle: PiCardBorderStyle = "dashed";

  constructor(
    private piSuggestionSharedGoalStateService: PiSuggestionSharedGoalStateService,
    private metricsFacade: MetricsFacade
  ) {}

  public ngOnInit(): void {
    this.subscribeMetricsSubject();
    this.subscribeMetricsImprovement();
    this.subscribeMetricsSuggestionReplacement();
  }

  public getMetricFeedbackSuggestion(metric: IQuantivePlusMetric, index: number): IPIFeedbackSuggestion {
    return { id: metric.id, text: metric.name, index };
  }

  public suggestAllMetrics = (): void => {
    this.allMetricsSuggest.emit();
  };

  public suggestAnotherMetric = (metricToReplace: IQuantivePlusMetric, metricToReplaceIndex: number): void => {
    this.metricSuggest.emit({ suggestionType: "metric", suggestionToReplace: metricToReplace, suggestionToReplaceIndex: metricToReplaceIndex });
  };

  public onGoBackToSuggestionClicked = (index: number): void => {
    this.goBackToSuggestion.emit({ suggestionIndex: index });
  };

  private subscribeMetricsSubject(): void {
    this.metricsInstance.subject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.refreshError && !data.suggestion) {
        this.allMetricsSuggest.emit();

        return;
      }

      const suggestion = this.metrics.find((kr) => kr.id === data.suggestion.id);
      if (!suggestion) {
        return;
      }

      if (data.suggestAnother || data.refreshError) {
        this.metricSuggest.emit({ suggestionType: "metric", suggestionToReplace: suggestion, suggestionToReplaceIndex: data.suggestion?.index });
      }

      if (data.removeSuggestion) {
        this.metricReject.emit(data.suggestion?.index);
      }

      if (data.addSuggestion) {
        const payload = getMetricPayload(this.goal, suggestion, this.metricsInstance);
        this.postMetric(payload, data.suggestion?.index);
      }
    });
  }

  private postMetric(payload: Partial<Metric>, quantivePlusSuggestionIndex: number): void {
    this.metricsFacade
      .post$(payload)
      .pipe(take(1))
      .subscribe((metric) => {
        this.metricCreated.emit({ metric, quantivePlusSuggestionIndex });

        if (this.metrics.length === 0) {
          this.piSuggestionSharedGoalStateService.setShouldSuggestQuantivePlusMetrics(false);
        }
      });
  }

  private subscribeMetricsImprovement(): void {
    this.metricsInstance.improvementSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const suggestionToReplace = this.metrics.find((kr) => kr.id === data.suggestion.id);
      if (!suggestionToReplace) {
        return;
      }

      this.piSuggestionSharedGoalStateService.setSingleMetric({
        type: "improving",
        args: { suggestionToReplace, suggestionToReplaceIndex: data.suggestion?.index, suggestionType: "metric" },
        improvement: data.improvement,
        improveSuggestionContext: this.metricsInstance.improveSuggestionContext,
      });
    });
  }

  private subscribeMetricsSuggestionReplacement(): void {
    this.metricsInstance.replacementSuggestionSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const suggestionToReplace = this.metrics.find((kr) => kr.id === data.suggestion.id);
      if (!suggestionToReplace) {
        return;
      }

      this.piSuggestionSharedGoalStateService.setSingleMetric({
        type: "replacing",
        args: { suggestionToReplace, suggestionToReplaceIndex: data.suggestion?.index, suggestionType: "metric" },
        newSuggestionName: data.newSuggestion.text,
      });
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Metric, MetricDTO } from "../models/metric.models";
import { MetricsState } from "./metrics-state.service";

@Injectable({
  providedIn: "root",
})
export class MetricsApiService extends BaseApiService<Metric, MetricDTO, MetricsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, metricsState: MetricsState) {
    super("metrics", { ...new ApiVersionModel("v1"), getAll: "v2", get: "v1" }, httpClient, appConfig, metricsState);
  }

  public getMetricHistoryPatchEndpoint(metricHistoryId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/checkin/${metricHistoryId}`;
  }

  public getMetricSnapshotDeleteEndpoint(snapshotId: string): string {
    return `${this.getBasePath(HttpActions.delete)}/checkin/${snapshotId}`;
  }

  public getMetricSnapshotPostEndpoint(metricId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${metricId}/checkin`;
  }

  public getPatchMetricEndpoint(metricId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${metricId}`;
  }

  public getCreateMetricEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}`;
  }

  public getUpdateMetricCheckinEndpoint(checkinId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/checkin/${checkinId}`;
  }

  public getMetricCheckinEndpoint(checkinId: string): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/checkin/${checkinId}`;
  }

  public getAddMetricTagsEndpoint(metricId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${metricId}/tags`;
  }

  public getPatchBulkTagsOperationEndpoint(): string {
    return `${this.getBasePath(HttpActions.patch)}/tags`;
  }

  public getRemoveMetricTagsByTitleEndpoint(metricId: string, tagTitles: string[]): string {
    const tags = tagTitles.map((x) => `"${x}"`).join(",");

    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v2" })}/${metricId}/tags?filter={title:{$in:[${encodeURIComponent(tags)}]}}`;
  }

  public getBulkDeleteMetricsEndpoint(): string {
    return `${this.getBasePath(HttpActions.delete)}/bulk`;
  }
}

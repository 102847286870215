import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { Intercom } from "@gtmhub/shared/intercom";
import { toggleIntercom } from "@gtmhub/shared/utils";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeamsStoreState } from "@gtmhub/teams";
import { getCurrentUserId } from "@gtmhub/users";
import { createDraftGoal, createDraftMetric } from "@gtmhub/whiteboards/whiteboards.utils";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { MetricTargetOperator } from "@webapp/okrs/metrics/models/metric.models";
import { generateAssignees } from "@webapp/platform-intelligence/quantive-plus/utils/utils";
import { PIKeyResultSuggestionPayload, PIObjectiveDescriptionSuggestionPayload } from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { StrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.service";
import { Session } from "@webapp/sessions/models/sessions.model";
import { PeopleSelectorRequest } from "@webapp/shared/components/people-selector/models/models";
import dayjs from "@webapp/shared/libs/dayjs";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { WhiteboardsFacade } from "@webapp/whiteboards/services/whiteboards-facade.service";
import { IQuantivePlusObjective } from "../quantive-plus/models";
import { PIStateProcessorInstanceSubType } from "../shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { IQuantivePlusMetric } from "../shared/models";
import { PiTrackingEventsEnum } from "../shared/utils/pi-tracking";
import { descriptionValidators, getMetricRowsNumber, validateWhitespaces } from "./utils";

export type GuidedOKRsModalData = {
  flowName?: string;
  currentSession: Session;
  readOnlySession?: boolean;
  parent?: { id: string; title: string; ownerIds: string[] };
};

@UntilDestroy()
@Component({
  selector: "guided-okrs",
  templateUrl: "./guided-okrs.component.html",
  styleUrls: ["./guided-okrs.component.less"],
})
export class GuidedOKRsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("parentTitleEl") public parentTitleElRef: ElementRef;

  public peopleSelectorRequestInternal;
  public selectedIds: string[] = [getCurrentUserId()];
  public suggestingObjectives = false;
  public suggestingKeyResults = false;
  public suggestingObjectivesError = false;
  public suggestingMoreObjectivesError = false;
  public addingOkrsToWhiteboardError = false;
  public currentSession: Session;
  public selectedSession: Session;
  public readOnlySession: boolean;
  public parent?: { id: string; title: string; ownerIds: string[] };
  private isAddingToWhiteboard = false;
  public hasSuggestedKrs = false;
  public hasSuggestedObjectives = false;
  public stage: "initial" | "objectives" | "keyresults" = "initial";
  public showConfirmationDialog = false;
  public assigneeIdMap: Record<string, Assignee>;
  public sessions: Session[];
  public whiteboardId: string;
  public objectiveForm: FormGroup;
  public flowName: string;
  public flowId: string;
  public firstPart: string;
  public secondPart: string;

  constructor(
    private modalRef: UiModalRef,
    private cd: ChangeDetectorRef,
    private strategicGuidedOkrFacade: StrategicGuidedOkrFacade,
    private formBuilder: FormBuilder,
    private whiteboardsFacade: WhiteboardsFacade,
    private analyticsService: AnalyticsService,
    private featureTogglesFacade: FeatureTogglesFacade,
    @Inject(UI_MODAL_DATA) modalData: GuidedOKRsModalData
  ) {
    this.setPeopleSelectorRequest();

    this.objectiveForm = this.formBuilder.group({
      objectives: this.formBuilder.array([], Validators.required),
      strategy: ["", [Validators.required, Validators.maxLength(500), validateWhitespaces]],
    });

    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ISessionsStoreState>();
    this.assigneeIdMap = state.assignees.map;
    this.sessions = state.sessions.items;

    this.flowId = uuidv4();

    this.analyticsService.track(PiTrackingEventsEnum.PiFlowInitiated, { flowName: this.flowName, flowId: this.flowId, userId: getCurrentUserId() });

    toggleIntercom({ hideLauncher: true });
  }

  private setPeopleSelectorRequest(): void {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.ManageOKRsGranularPermissions)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (hasManageOKRsGranularPermissionsEnabled) => {
          const requiredOwnerPermission = hasManageOKRsGranularPermissionsEnabled ? "goals:own" : "ManageGoals";
          this.peopleSelectorRequestInternal = {
            ...new PeopleSelectorRequest(),
            permissionSetting: { enabled: true, permissions: [requiredOwnerPermission] },
            hideViewOnlyUsers: hasManageOKRsGranularPermissionsEnabled,
          };
        },
      });
  }

  public ngOnDestroy(): void {
    toggleIntercom({ hideLauncher: false });
  }

  public ngAfterViewInit(): void {
    if (!this.parent) return;

    this.truncateTextInMiddle(this.parent.title);

    if (this.firstPart && this.secondPart) {
      this.cd.detectChanges();
    }
  }

  public openIntercom(): void {
    toggleIntercom({ hideLauncher: false });

    if (Intercom) {
      Intercom("show");
    }
  }

  public changeSelectedSession(sessionId: string): void {
    this.currentSession = this.sessions.find((s) => s.id === sessionId);
  }

  public closeModal(): void {
    this.modalRef.destroy();
  }

  get getStrategy(): FormControl {
    return this.objectiveForm.get("strategy") as FormControl;
  }

  get getObjectives(): FormArray {
    return this.objectiveForm.get("objectives") as FormArray;
  }

  public isEditing(): boolean {
    return this.getObjectives.value.some((obj) => obj.isEdit || obj.keyResults.some((kr) => kr.isEdit));
  }

  private truncateTextInMiddle(text: string): void {
    text = text.normalize("NFC");

    const graphemes = Array.from(text);

    const parentTitleEl = this.parentTitleElRef.nativeElement;

    if (parentTitleEl.scrollWidth <= 580) {
      return;
    }

    const mid = Math.floor(graphemes.length / 2);
    this.firstPart = graphemes.slice(0, mid).join("");
    this.secondPart = graphemes.slice(mid).join("");
  }

  private addObjectiveFormGroup(obj: IQuantivePlusObjective): FormGroup {
    const assigneeIds = ([...obj.assignees.users, ...obj.assignees.teams] || []).map((assignee) => assignee.id);

    return this.formBuilder.group({
      ...obj,
      isManual: false,
      description: [obj.description, descriptionValidators],
      title: [obj.title, [Validators.required, validateWhitespaces]],
      keyResults: this.formBuilder.array([]),
      isEdit: false,
      isFetchingDescription: false,
      isFetchingDescriptionError: false,
      isFetchingKeyResultSuggestions: false,
      isFetchedKeyResultSuggestions: false,
      isFetchingKeyResultSuggestionsError: false,
      previousSavedTitle: "",
      previousSavedDescription: "",
      showActions: false,
      prevAssignees: [],
      assignees: [assigneeIds, [Validators.required]],
    });
  }

  private addKeyResultFormGroup(kr: IQuantivePlusMetric): FormGroup {
    return this.formBuilder.group({
      ...kr,
      title: [kr.title, [Validators.required, validateWhitespaces]],
      isEdit: false,
      previousSavedTitle: "",
    });
  }

  private buildForm(objectives: IQuantivePlusObjective[]): void {
    this.getObjectives.clear();

    objectives.forEach((obj) => {
      this.getObjectives.push(this.addObjectiveFormGroup(obj));
    });
  }

  public reset(state: boolean): void {
    this.showConfirmationDialog = state;
  }

  public suggestObjectives(): void {
    this.suggestingObjectivesError = false;
    this.suggestingObjectives = true;
    this.stage = "objectives";

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const oldObjectives = structuredClone(this.getObjectives.value);
    const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

    const payload = {
      strategy: this.objectiveForm.get("strategy").value,
      assignees: assignees,
      existingObjs: oldObjectives,
      nSuggestions: 4,
      subEntityType: "objectives" as PIStateProcessorInstanceSubType,
    };

    this.strategicGuidedOkrFacade
      .getObjectiveSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (suggestion) => {
          this.buildForm([...oldObjectives, ...suggestion.suggestions.objectives]);

          this.suggestingObjectives = false;
          this.hasSuggestedObjectives = true;
          this.cd.detectChanges();
        },
        () => {
          this.suggestingObjectives = false;
          this.suggestingObjectivesError = true;
        }
      );
  }

  public suggestMoreObjectives(): void {
    this.suggestingMoreObjectivesError = false;
    this.suggestingObjectives = true;

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const oldObjectives = structuredClone(this.getObjectives.value);
    const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

    const payload = {
      strategy: this.objectiveForm.get("strategy").value,
      assignees: assignees,
      existingObjs: oldObjectives,
      nSuggestions: 4,
      subEntityType: "objectives" as PIStateProcessorInstanceSubType,
    };

    this.strategicGuidedOkrFacade
      .getObjectiveSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (suggestion) => {
          suggestion.suggestions.objectives.forEach((obj) => {
            this.getObjectives.push(this.addObjectiveFormGroup(obj));
          });

          this.suggestingObjectives = false;
          this.cd.detectChanges();
        },
        () => {
          this.suggestingObjectives = false;
          this.suggestingMoreObjectivesError = true;
        }
      );
  }

  public back(): void {
    if (this.stage === "objectives") {
      this.getObjectives.clear();
      this.stage = "initial";

      this.hasSuggestedObjectives = false;
      this.suggestingObjectivesError = false;
      this.suggestingMoreObjectivesError = false;
    }

    if (this.stage === "keyresults") {
      this.getObjectives.value.forEach((obj, i) => {
        const objective = this.getObjectives.at(i);
        objective.patchValue({
          isFetchingKeyResultSuggestions: false,
          isFetchedKeyResultSuggestions: false,
          isFetchingKeyResultSuggestionsError: false,
        });

        const objectiveKeyResults = objective.get("keyResults") as FormArray;
        objectiveKeyResults.clear();
      });

      this.stage = "objectives";
      this.hasSuggestedKrs = false;
    }

    this.reset(false);
  }

  public suggestKeyResults(): void {
    this.suggestingMoreObjectivesError = false;
    this.stage = "keyresults";
    this.suggestingKeyResults = true;

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const objectives = this.getObjectives.value.map((objective) => {
      const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

      return {
        title: objective.title,
        description: objective.description,
        existingKrs: objective.keyResults.map((kr) => ({ title: kr.title, description: kr.description })),
        assignees: assignees,
        nSuggestions: 3,
      };
    });

    this.getObjectives.value.forEach((obj, i) => {
      const objective = this.getObjectives.at(i);
      objective.patchValue({ isFetchingKeyResultSuggestions: true, showActions: false, isFetchingDescriptionError: false });
    });

    const payload: PIKeyResultSuggestionPayload = {
      objectives: objectives,
    };

    this.strategicGuidedOkrFacade
      .getKeyResultSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          data.suggestions.forEach((obj, i) => {
            const objective = this.getObjectives.at(i);
            objective.patchValue({ isFetchingKeyResultSuggestions: false, isFetchedKeyResultSuggestions: true });
            const krs = this.getObjectives.at(i).get("keyResults") as FormArray;
            obj.keyresults.forEach((kr) => {
              krs.push(this.addKeyResultFormGroup(kr));
            });
          });

          this.suggestingKeyResults = false;
          this.hasSuggestedKrs = true;
        },
        () => {
          this.suggestingKeyResults = false;
          this.getObjectives.value.forEach((obj, i) => {
            const objective = this.getObjectives.at(i);
            objective.patchValue({ isFetchingKeyResultSuggestions: false, isFetchingKeyResultSuggestionsError: true });
          });
        }
      );
  }

  public confirmObjective(objIndex: number): void {
    const objective = this.getObjectives.at(objIndex);

    if (!objective.value.description) {
      this.suggestObjectiveDescription(objective);
    }
  }

  public refreshObjectiveDescription(objIndex: number): void {
    const objective = this.getObjectives.at(objIndex);
    this.suggestObjectiveDescription(objective);
  }

  private suggestObjectiveDescription(objective: AbstractControl): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const assignees = generateAssignees(objective.value.assignees, state.assignees.map, state.teams.items);
    const payload: PIObjectiveDescriptionSuggestionPayload = {
      entityId: objective.value.id,
      subEntityType: "description",
      objectives: [
        {
          title: objective.value.title,
          assignees: assignees,
        },
      ],
    };

    objective.patchValue({
      isFetchingDescription: true,
      isEdit: false,
    });

    this.strategicGuidedOkrFacade
      .getObjectiveDescriptionSuggestion(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (descriptions) => {
          objective.patchValue({
            description: descriptions.suggestions[0].descriptions[0].description,
            isManual: false,
            isEdit: false,
            isFetchingDescription: false,
          });

          objective.get("description").addValidators(descriptionValidators);
          objective.updateValueAndValidity();

          if (this.hasSuggestedKrs) {
            this.suggestMoreKeyResults(this.getObjectives.length - 1);
          }
        },
        () => {
          objective.patchValue({
            isManual: false,
            isEdit: false,
            isFetchingDescription: false,
            isFetchingDescriptionError: true,
          });
        }
      );
  }

  public isFetchingDescription(): boolean {
    return this.getObjectives.value.some((obj) => obj.isFetchingDescription);
  }

  public addObjective(): void {
    this.suggestingObjectivesError = false;
    this.suggestingMoreObjectivesError = false;

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>();
    const currentAssignee = state.assignees.map[getCurrentUserId()];

    const objective = {
      title: "",
      description: "",
      assignees: { users: [{ id: currentAssignee.id, name: currentAssignee.name, team: [] }], teams: [] },
      isManual: false,
      isEdit: false,
      isFetchingDescription: false,
      isFetchingKeyResultSuggestions: false,
    };

    const objectiveFormGroup = this.addObjectiveFormGroup(objective);
    objectiveFormGroup.get("description").removeValidators(descriptionValidators);
    objectiveFormGroup.get("description").updateValueAndValidity();

    objectiveFormGroup.patchValue({ isEdit: true, isManual: true });

    this.getObjectives.push(objectiveFormGroup);
  }

  public addKeyResult(objIndex): void {
    const krs = this.getObjectives.at(objIndex).get("keyResults") as FormArray;
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>();
    const kr = {
      title: "",
      description: "",
      assignee: state.assignees.map[getCurrentUserId()].name,
      isManual: true,
      isEdit: false,
      format: {
        prefix: "",
        suffix: "%",
        fractionSize: 0,
      },
      initialValue: 0,
      target: 10,
      targetOperator: "at_least" as MetricTargetOperator,
    };

    const krFormGroup = this.addKeyResultFormGroup(kr);
    krFormGroup.patchValue({ isEdit: true });
    krs.push(krFormGroup);
  }

  public suggestMoreKeyResults(objIndex: number): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const objective = this.getObjectives.at(objIndex);
    objective.patchValue({
      isFetchingKeyResultSuggestions: true,
      isFetchingKeyResultSuggestionsError: false,
      showActions: false,
    });

    const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

    const objectives = {
      title: objective.value.title,
      description: objective.value.description,
      existingKrs: objective.value.keyResults.map((kr) => ({ title: kr.title, description: kr.description })),
      assignees: assignees,
      nSuggestions: 3,
    };

    const payload: PIKeyResultSuggestionPayload = {
      objectives: [objectives],
    };

    this.strategicGuidedOkrFacade
      .getKeyResultSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          data.suggestions.forEach((obj) => {
            const krs = objective.get("keyResults") as FormArray;
            obj.keyresults.forEach((kr) => {
              krs.push(this.addKeyResultFormGroup(kr));
            });
          });

          objective.patchValue({
            isFetchingKeyResultSuggestions: false,
            isFetchedKeyResultSuggestions: true,
          });
        },
        () => {
          objective.patchValue({
            isFetchingKeyResultSuggestions: false,
            isFetchingKeyResultSuggestionsError: true,
          });
        }
      );
  }

  public isSuggestingMoreKeyResults(): boolean {
    return this.getObjectives.value.some((obj) => obj.isFetchingKeyResultSuggestions);
  }

  public removeKeyResult(event: { metricIndex: number; objIndex: number }): void {
    const krs = this.getObjectives.at(event.objIndex).get("keyResults") as FormArray;
    krs.removeAt(event.metricIndex);
  }

  public removeObjective(objIndex): void {
    this.getObjectives.removeAt(objIndex);
  }

  public addOkrsToWhiteboard(): void {
    this.addingOkrsToWhiteboardError = false;

    if (this.isAddingToWhiteboard) {
      return;
    }

    this.isAddingToWhiteboard = true;
    const objectives = structuredClone(this.objectiveForm.value.objectives);

    // creating 2d/matrix array so that we can later use rows/cols to move on coordinate system
    const objectives2d = [];
    while (objectives.length) objectives2d.push(objectives.splice(0, 4));

    const paddingTop = 60;
    const rowHight = 30;
    const draftGoalAndDraftMetricRows = 3; // adding the goal title and 'add metric' button + 1 metric index bcs they start at 0

    const shapes = [];
    let maxRows = 0;

    for (const [row, objectives] of objectives2d.entries()) {
      let maxColRows = 0;

      for (const [col, objective] of objectives.entries()) {
        const ownerIds = objective.assignees;

        const metrics = objective.keyResults.map((metric) =>
          createDraftMetric({
            name: metric.title,
            ownerIds,
            description: metric.description,
            initialValue: metric.initial,
            target: metric.target,
            targetOperator: metric.direction,
          })
        );
        const rows = getMetricRowsNumber(metrics) + draftGoalAndDraftMetricRows;

        if (rows >= maxColRows) {
          maxColRows = rows;
        }

        const y = row === 0 ? 0 : maxRows * rowHight;
        const position = { x: col * 360, y: row === 0 ? y : y + row * paddingTop };
        const goal = createDraftGoal(objective.title, ownerIds, position, this.currentSession.id, this.parent && { id: this.parent.id, type: "goal" });
        const draftGoal = { ...goal, metrics };

        shapes.push(draftGoal);
      }

      maxRows += maxColRows;
    }

    const currentUser = this.assigneeIdMap[getCurrentUserId()];

    const payload = {
      name: `${currentUser.name}'s suggestions ${dayjs().format("MM.DD.YYYY HH:mm")}`,
      state: { shapes },
    };

    const goals = this.getObjectives.value;
    const metrics = goals.map((g) => g.keyResults).flat();
    const ids = [...goals, ...metrics].filter((item) => item.id).map((item) => item.id);

    this.whiteboardsFacade
      .createWhiteboard(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (wb) => {
          this.isAddingToWhiteboard = false;

          this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionAccepted, {
            flowName: this.flowName,
            flowId: this.flowId,
            userId: getCurrentUserId(),
            goalAndMetricSuggestionIds: ids,
          });

          window.open(`/#/whiteboards/${wb.id}/`, "_self");
        },
        error: () => {
          this.addingOkrsToWhiteboardError = true;
          this.isAddingToWhiteboard = false;
        },
      });
  }
}

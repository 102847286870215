import { Injectable } from "@angular/core";
import { Observable, map, switchMap } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { PermissionsApiService } from "./permissions-api.service";
import { PermissionsFacade } from "./permissions-facade.service";
import { PermissionsState } from "./permissions-state.service";

@Injectable({
  providedIn: "root",
})
export class PermissionsFacadeWithFeatureFlag extends PermissionsFacade {
  constructor(
    state: PermissionsState,
    api: PermissionsApiService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {
    super(state, api);
  }

  public hasPermissionWithFeatureFlag$(
    featureFlag: FeatureFlag,
    permissionWhenFlagIsOn: Permission | boolean,
    permissionWhenFlagIsOff: Permission | boolean
  ): Observable<boolean> {
    return this.featureTogglesFacade.isFeatureAvailable$(featureFlag).pipe(
      switchMap((isFeatureAvailable) => {
        const flagToCheck = isFeatureAvailable ? permissionWhenFlagIsOn : permissionWhenFlagIsOff;
        if (typeof flagToCheck === "boolean") {
          return this.getPermissions$().pipe(map(() => flagToCheck));
        }

        return this.getPermissions$().pipe(map((permissions) => permissions.has(flagToCheck)));
      })
    );
  }
}

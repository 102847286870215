import { OverlayRef } from "@angular/cdk/overlay";
import { uiToNz } from "@quantive/ui-kit/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { BaseModalContainerComponent, ModalOptions, NzModalRef, NzTriggerAction } from "ng-zorro-antd/modal";
import { CustomModalOptions, UiModalOptions } from "../modal.models";

export class UiModalRef<T = NzSafeAny, R = NzSafeAny> extends NzModalRef<T, R> {
  constructor(
    modalOverlayRef: OverlayRef,
    config: ModalOptions,
    public containerInstance: BaseModalContainerComponent
  ) {
    super(modalOverlayRef, config, containerInstance);
  }

  public updateConfig(config: UiModalOptions | ModalOptions): void {
    super.updateConfig(uiToNz(config) as ModalOptions);
  }

  public trapFocus(): void {
    Promise.resolve().then(() => this.containerInstance["trapFocus"]());
  }
}

// `trigger` is a private method and we want to extend it to support errors from Promise results
const trigger = UiModalRef.prototype["trigger"];
UiModalRef.prototype["trigger"] = function (action: NzTriggerAction): Promise<void> {
  if (action !== NzTriggerAction.OK) {
    return trigger.call(this, action);
  }

  const config = this["config"] as CustomModalOptions;
  config.nzOkDisabled = true;
  config.nzLoadingIndicator = true;
  config.nzError = null;

  const promise = trigger.call(this, action) as Promise<void>;
  return promise.catch((error) => {
    config.nzOkDisabled = false;
    config.nzLoadingIndicator = false;
    config.nzError = error;
  });
};

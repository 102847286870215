import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { ItemWithAccessType, Permission, PermissionDTO } from "@webapp/permissions/models/permissions.model";
import { PermissionsState } from "@webapp/permissions/services/permissions-state.service";

@Injectable({
  providedIn: "root",
})
export class PermissionsApiService extends BaseApiService<Permission, PermissionDTO, PermissionsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: PermissionsState) {
    super("permissions", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getEffectivePermissions$(filters?: RequestPaging, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Permission[]> {
    return this.getAll$<Permission[]>(filters, {
      ...new RequestConfig(),
      url: this.getEffectivePermissionsPath(HttpActions.getAll),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams),
    });
  }

  private getEffectivePermissionsPath(httpAction: HttpActions): string {
    return `${this.getBasePath(httpAction)}/effective`;
  }

  public getCurrentUserAllowedActionsPerItemEndpoint(targetId: string): string {
    return `${this.getBasePath(HttpActions.get)}/currentUser-allowedActions/${targetId}`;
  }

  public getUsersWithAllowedActionPerItemEndpoint(params: { targetType: "goal" | "metric"; targetId: string }): string {
    return `${this.getBasePath(HttpActions.get)}/${params.targetType}/${params.targetId}/users`;
  }

  public getDefaultAccessByTypeEndpoint(itemType: ItemWithAccessType): string {
    return `${this.getBasePath(HttpActions.get)}/default-access/${itemType}`;
  }

  public getDefaultAccessByTypeAndIdEndpoint(itemType: ItemWithAccessType, itemId: string): string {
    return `${this.getBasePath(HttpActions.get)}/default-access/${itemType}/${itemId}`;
  }

  public getPermissionActionsV2Endpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v2" })}/actions`;
  }

  public getPermissionsByPrincipalEndpoint(principalId: string): string {
    return `${this.getBasePath(HttpActions.get)}/principal/${principalId}`;
  }

  public getAvailableProductsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/available-products`;
  }

  public updatePermissionsByPrincipalEndpoint(principalId: string): string {
    return `${this.getBasePath(HttpActions.put)}/principal/${principalId}`;
  }

  public getAllowedActionsForTargetType(targetType: string): string {
    return `${this.getBasePath(HttpActions.put, { apiVersionOverwrite: "v2" })}/allowed-actions/${targetType}`;
  }
}

import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module, registerChildFutureState } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "kpis" */ "./kpis.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const kpisFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.kpis",
    url: "/kpis/",
    lazyLoad,
  },
  {
    name: "gtmhub.kpis._singleKpi",
    abstract: true,
  },
  {
    name: "gtmhub.kpis._singleKpi.new.**",
    url: "new/",
    lazyLoad,
  },
  {
    name: "gtmhub.kpis._singleKpi.form.**",
    url: ":kpiId/edit/?groupId",
    lazyLoad,
  },
  {
    name: "gtmhub.kpis.details",
    url: ":kpiId/details/?groupId",
    lazyLoad,
  },
  {
    name: "gtmhub.kpis.details._singleKpi",
    abstract: true,
  },
  {
    name: "gtmhub.kpis.details._singleKpi.form.**",
    url: "edit/",
    lazyLoad,
  },
  {
    name: "gtmhub.kpis.details.comments.**",
    url: "comments/",
    lazyLoad,
  },
  {
    name: "gtmhub.kpis.details.comment.**",
    url: ":commentId/comment/",
    lazyLoad,
  },
  {
    name: `gtmhub.kpis.grid`,
    url: "grid/",
    lazyLoad,
  },
];

export const states: Ng2StateDeclaration[] = [...kpisFutureStates];

export const kpiDetailsFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base: `${base}._kpis`, factory: "kpiDetailsStates" });
  return [
    {
      name: `${base}._kpis`,
      abstract: true,
      url: "kpis/",
    },
    {
      name: `${base}._kpis.details.**`,
      url: ":kpiId/details/",
      lazyLoad,
    },
  ];
};

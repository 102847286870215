<ng-container *ngIf="isQuantiveTeamsEditionPlan; else otherPlan">
  <feature-banner [cssClasses]="'sp-mt-20'"></feature-banner>
</ng-container>

<ng-template #otherPlan>
  <div *ngIf="!isQuantiveTeamsEditionPlan" class="kr-with-kpi-automation-wrapper">
    <related-items-selector [allowedTypes]="allowedTypes" [relationsToExclude]="relationsToExclude" [triggerOpen]="false" (relatedItemChange)="onUpdateValue($event)"></related-items-selector>
    <gh-indicator [(ghValue)]="indicators.loadingKpiValue" [align]="'center'" [ghMessage]="'loading' | localize"></gh-indicator>
    <div class="kpi-value-wrapper" *ngIf="isKpiSelected && !indicators.loadingKpiValue">
      <div class="value-label">{{ "value" | localize }}</div>
      <span class="value">{{ kpiCurrentValue }}</span>
    </div>
  </div>
</ng-template>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, filter, finalize, map, merge, take, tap, zip } from "rxjs";
import { IIndicatorMap, UIErrorHandlingService } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesRepository } from "@webapp/assignees/services/assignees-repository.service";
import { sortAssigneeIdsByActiveStatus } from "@webapp/assignees/utils/assignee.utils";
import {
  AggregatedMentions,
  Comment,
  CommentTarget,
  CommentsOrdering,
  CommentsRepository,
  CommentsScrollToEditorEvent,
  InitialMentionsService,
  UiComment,
} from "@webapp/comments";
import { CommentsFacade } from "@webapp/comments/services/comments-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { createErrorObject } from "@webapp/error-handling/error-util";
import { toIdMap } from "@webapp/okrs/components/create-okr-form/utils";
import { checkinTargetTypes } from "@webapp/reflections/models/reflections.models";
import { Gif } from "@webapp/shared/components/gifs/gif.models";
import { ConfirmDeleteService } from "@webapp/shared/modal/confirm-delete.service";
import { RichTextEditorComponent } from "@webapp/shared/rich-text-editor/rich-text-editor.component";
import { CurrentUserRepository } from "@webapp/users";
import { CommentComponent } from "../comment/comment.component";

const COMMENTS_REQUEST_FIELDS: (keyof Comment)[] = [
  "id",
  "createdAt",
  "createdBy",
  "editedBy",
  "mentioned",
  "reactions",
  "seen",
  "targetId",
  "targetTitle",
  "targetType",
  "text",
  "gif",
];

@UntilDestroy()
@Component({
  selector: "comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.less"],
  providers: [InitialMentionsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent implements OnChanges, OnInit {
  @Input() public target: CommentTarget;
  @Input() public inputPlaceholder: string;
  @Input() public metadata: { [key: string]: string | boolean };
  @Input() public initialMentions: string[];
  @Input() public skipFetchingComments = false;
  @Input() public externalActionButtons = false;
  @Input() public commentOptionsDropdownPosition: "start" | "end" = "start";
  @Input() public scrollToEditorEvent: Observable<CommentsScrollToEditorEvent>;
  @Input() public focusEditor = false;
  @Input() public editorInitialText = "";
  @Input() public hideMentions = false;
  @Input() public scrollToComment: string;
  @Input() public commentsSorting: CommentsOrdering = this.currentUserRepository.getUserSetting("commentsOrdering");

  @Output() public readonly add: EventEmitter<Comment> = new EventEmitter();
  @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();
  @Output() public readonly remove: EventEmitter<Comment> = new EventEmitter();
  @Output() public readonly textChange: EventEmitter<string> = new EventEmitter();
  @Output() public readonly commentsChange: EventEmitter<Comment[]> = new EventEmitter();

  public indicators: IIndicatorMap = {};
  public comments: UiComment[] = [];

  public mentions: AggregatedMentions = {
    removable: [],
    nonRemovable: [],
  };

  public editorButtonsDisabled: boolean;
  public editedCommentId: string;
  public currentUserId: string;
  public removeAllMentionsAriaLabel: string;
  private assigneesMap: Record<string, Assignee>;

  public constructor(
    private currentUserRepository: CurrentUserRepository,
    private commentsFacade: CommentsFacade,
    private commentsRepository: CommentsRepository,
    private confirmDeleteService: ConfirmDeleteService,
    private initialMentionsService: InitialMentionsService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private changeDetector: ChangeDetectorRef,
    private broadcastService: BroadcastService,
    private analyticsService: AnalyticsService,
    private assigneesRepository: AssigneesRepository
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialMentions && !changes.initialMentions.isFirstChange()) {
      this.initRemovableMentions();
    }

    if (changes.commentsSorting && !changes.commentsSorting.isFirstChange()) {
      this.comments.reverse();
    }
  }

  public ngOnInit(): void {
    this.subscribeToExternalCommentChanges();

    this.currentUserId = getCurrentUserId();

    if (this.skipFetchingComments) {
      this.comments = [];
      this.initRemovableMentions();
    } else {
      this.loadComments();
    }

    if (this.scrollToEditorEvent) {
      this.scrollToEditorEvent.pipe(untilDestroyed(this)).subscribe((event) => {
        if (event.scrollToEditor && this.target.id === event?.targetId) {
          this.focusEditor = event.scrollToEditor;
        } else {
          this.focusEditor = false;
        }
      });
    }
  }

  private get isTargetKpi(): boolean {
    return this.target.type === "kpi_snapshot" || this.target.type === "kpi_projection";
  }

  private get isTargetCheckins(): boolean {
    return checkinTargetTypes.includes(this.target.type);
  }

  public removeMention(mentionId: string): void {
    this.mentions.removable = this.mentions.removable.filter((mention) => mention !== mentionId);
  }

  public removeAllMentions(): void {
    this.mentions.removable = [];
  }

  public onCommentMentionsChanged(mentions: string[]): void {
    this.mentions.nonRemovable = sortAssigneeIdsByActiveStatus(mentions, this.assigneesMap);

    // removes duplicated mention IDs from the removable mentions array
    const nonRemovable = new Set(this.mentions.nonRemovable);
    this.mentions.removable = (this.mentions.removable || []).filter((mentionId) => !nonRemovable.has(mentionId));

    this.changeDetector.markForCheck();
  }

  public onCommentCreationCanceled(editor: RichTextEditorComponent): void {
    editor.clearContent();
    this.cancel.emit();
  }

  public onCommentPosted(comment: Comment): void {
    if (this.commentsSorting === "createdAt") {
      this.comments.push(comment);
    } else {
      this.comments.unshift(comment);
    }
    this.initRemovableMentions();
    this.add.emit(comment);
    this.commentsChange.emit(this.comments);
  }

  public onCommentEdited(comment: Comment): void {
    this.editedCommentId = null;

    const oldCommentIndex = this.comments.findIndex((listComment) => listComment.id === comment.id);
    if (oldCommentIndex !== -1) {
      // replace the old comment object with a new object ref to get the changes reflected in the child component
      const oldComment = this.comments[oldCommentIndex];
      const updatedComment = Object.assign({}, oldComment, comment);
      this.comments.splice(oldCommentIndex, 1, updatedComment);

      this.initRemovableMentions();
      this.commentsChange.emit(this.comments);
    }
  }

  public onTextChanged(text: string): void {
    this.textChange.emit(text);
  }

  public closeInlineEditor(): void {
    this.editedCommentId = null;
    this.changeDetector.detectChanges();
  }

  public openCommentDeleteConfirmation(comment: Comment, component: CommentComponent): void {
    const { title, description } = this.generateConfirmDeleteCopy(comment);
    const commentType = this.comments[0].id === comment.id ? "comment" : "reply";

    this.confirmDeleteService.confirmDelete({ title, description }).subscribe({
      next: () => {
        this.editorButtonsDisabled = true;
        this.changeDetector.markForCheck();

        if (this.isTargetCheckins) {
          if (!this.metadata) {
            this.metadata = {};
          }
          this.metadata["type"] = commentType;
        }

        if (this.isTargetKpi) {
          this.metadata = { type: commentType };
        }

        this.commentsFacade
          .deleteComment$({ commentId: comment.id, commentAuthorId: comment.createdBy, metadata: this.metadata })
          .pipe(
            take(1),
            untilDestroyed(this),
            finalize(() => {
              this.editorButtonsDisabled = false;
              this.changeDetector.markForCheck();
            })
          )
          .subscribe({
            next: () => {
              this.comments = this.comments.filter((c) => c.id !== comment.id);
              this.initRemovableMentions();
              this.remove.emit(comment);
              this.commentsChange.emit(this.comments);
            },
            error: (error) => {
              this.uiErrorHandlingService.handleModal(error);
            },
          });
      },
      complete: () => {
        component.focusWrapperElement();
      },
    });
  }

  public createComment(editor: RichTextEditorComponent, { text, mentions, gif }: { text: string; mentions: string[]; gif: Gif }): void {
    this.editorButtonsDisabled = true;
    this.changeDetector.markForCheck();
    if (this.isTargetKpi || this.isTargetCheckins) {
      if (!this.metadata) {
        this.metadata = {};
      }
      this.metadata["type"] = this.comments.length === 0 ? "comment" : "reply";
      // numberOfReplies = number of all current comments - leading comment + comment to be created = comments length before creation
      this.metadata["numberOfReplies"] = this.comments.length.toString();
    }

    this.commentsRepository
      .postComment$(
        {
          targetId: this.target.id,
          targetType: this.target.type,
          targetTitle: this.target.title,
          text: text,
          mentioned: mentions,
          gif: gif,
          ...(this.target.date && { targetDate: this.target.date }),
        },
        this.metadata
      )
      .pipe(
        take(1),
        untilDestroyed(this),
        finalize(() => {
          this.editorButtonsDisabled = false;
          this.changeDetector.markForCheck();
          // the numberOfReplies metadata param is needed when we are creating a comment but once a comment is created,
          // this params stays in the metadata but on comment edit it is not needed
          delete this.metadata.numberOfReplies;
        })
      )
      .subscribe({
        next: (res) => {
          this.onCommentPosted(res);
          editor.clearContent();
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(createErrorObject(error));
        },
      });
  }

  public editComment(args: { text: string; mentions: string[]; gif: Gif }): void {
    const editedComment = this.editedCommentId && this.comments.find((comment) => comment.id === this.editedCommentId);
    if (!editedComment) {
      return;
    }

    this.editorButtonsDisabled = true;
    this.changeDetector.markForCheck();
    if (this.isTargetKpi || this.isTargetCheckins) {
      const commentType = this.comments[0].id === this.editedCommentId ? "comment" : "reply";
      if (!this.metadata) {
        this.metadata = {};
      }
      this.metadata["type"] = commentType;
    }

    this.commentsRepository
      .edit$(
        this.editedCommentId,
        {
          text: args.text,
          mentioned: args.mentions,
          gif: args.gif ?? { id: "", searchQuery: "" },
        },
        {
          isDeleted: Boolean(editedComment.gif?.id && !args.gif?.id),
          isAdded: Boolean(!editedComment.gif?.id && args.gif?.id),
        },
        this.metadata
      )
      .pipe(
        take(1),
        untilDestroyed(this),
        finalize(() => {
          this.editorButtonsDisabled = false;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe({
        next: (res) => {
          this.onCommentEdited(res);
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(createErrorObject(error));
        },
      });
  }

  public openInlineEditor(commentId: string): void {
    this.editedCommentId = commentId;
  }

  public commentsTrackBy(index: number, comment: Comment): string {
    return comment.id;
  }

  private generateConfirmDeleteCopy(commentToBeDeleted: Comment): { title: string; description: string } {
    const defaultTitle = localize("confirm_delete_comment_title");
    const defaultDescription = localize("this_cant_be_undone");
    if (!this.isTargetKpi) {
      return { title: defaultTitle, description: defaultDescription };
    }

    const commentIndex = this.comments.findIndex((comment) => comment.id === commentToBeDeleted.id);

    if (commentIndex === 0) {
      return { title: defaultTitle, description: `${localize("confirm_delete_comment_and_replies_content")} ${defaultDescription}` };
    } else {
      return { title: localize("confirm_delete_reply_title"), description: defaultDescription };
    }
  }

  private initRemovableMentions(): void {
    this.mentions.removable = this.initialMentionsService.getRemovableMentions({
      initialMentions: this.initialMentions,
      comments: this.comments,
    });

    this.assigneesRepository
      .getMap$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((assignees) => {
        this.assigneesMap = toIdMap(assignees);
        const names = (this.mentions.removable || []).map((mentionId) => assignees.get(mentionId)?.name ?? localize("deleted_user"));
        this.removeAllMentionsAriaLabel = `${localize("remove_all")}. ${localize("people_notified")}: ${names.join(", ")}`;
        this.mentions.removable = sortAssigneeIdsByActiveStatus(this.mentions.removable, this.assigneesMap);
        this.changeDetector.markForCheck();
      });
  }

  private loadComments(): void {
    this.indicators = { loading: { progress: true } };
    this.changeDetector.markForCheck();
    this.setCommentsSort();

    const filter = { fields: COMMENTS_REQUEST_FIELDS };
    const sort = this.commentsSorting;
    const targetDateParams = this.target.date ? { targetDate: this.target.date } : {};
    const queryParams = {
      targetIds: [this.target.id],
      targetType: this.target.type,
      sort,
      ...targetDateParams,
    };

    this.commentsRepository
      .getComments$(filter, queryParams)
      .pipe(
        take(1),
        untilDestroyed(this),
        finalize(() => {
          delete this.indicators.loading;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe({
        next: (comments) => {
          this.comments = comments;
          this.initRemovableMentions();
          comments.forEach((comment) => (comment.mentioned = sortAssigneeIdsByActiveStatus(comment.mentioned, this.assigneesMap)));
          this.markAsSeenAndDetectFirstUnreadComment();
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(error);
        },
      });
  }

  private setCommentsSort(): void {
    this.commentsSorting = this.currentUserRepository.getUserSetting("commentsOrdering");
  }

  private markAsSeenAndDetectFirstUnreadComment(): void {
    const commentsTheCurrentUserIsMentionedInAndHasNotSeen = this.comments.filter((comment) => {
      const isMentioned = comment.mentioned?.some((mentionId) => this.currentUserId === mentionId);
      const hasSeen = comment.seen?.some((seenUserId) => this.currentUserId === seenUserId);
      return isMentioned && !hasSeen;
    });

    this.markAllAsSeen(commentsTheCurrentUserIsMentionedInAndHasNotSeen);
    this.enrichTheFirstUnreadComment(commentsTheCurrentUserIsMentionedInAndHasNotSeen);
  }

  private markAllAsSeen(commentsToBeMarkedAsSeen: UiComment[]): void {
    const markAsSeenCommentsObservablesArr = commentsToBeMarkedAsSeen.map((comment) => {
      return this.commentsRepository.markAsSeen$(comment.id).pipe(
        take(1),
        untilDestroyed(this),
        tap(() => {
          comment.seen = comment.seen || [];
          comment.seen.push(this.currentUserId);
        })
      );
    });

    zip(markAsSeenCommentsObservablesArr).subscribe(() => {
      this.changeDetector.markForCheck();
      if (this.isTargetCheckins) {
        this.analyticsService.track("Comment Seen", {
          checkin_id: this.metadata?.checkinId,
          target_type: this.metadata?.targetType,
          comment_seen: true,
        });
      }
    });
  }

  private enrichTheFirstUnreadComment(unreadCommentsMentioningCurrentUser: UiComment[]): void {
    const thereIsACommentToEnrichAndIsNotLeading =
      this.comments.length > 0 && unreadCommentsMentioningCurrentUser.length > 0 && this.comments[0].id !== unreadCommentsMentioningCurrentUser[0].id;
    if (thereIsACommentToEnrichAndIsNotLeading) {
      unreadCommentsMentioningCurrentUser[0].isFirstUnreadByCurrentUser = true;
    }
  }

  private subscribeToExternalCommentChanges(): void {
    merge(
      // an automatic comment is posted on okr archive/reopen
      this.broadcastService.on<string>("commentPostedExternally"),
      // an automatic comment is posted on okr approve/decline
      this.broadcastService.on<{ targetId: string }>("commentPostedFromWorkflowUpdate").pipe(map(({ targetId }) => targetId))
    )
      .pipe(
        filter((targetId) => targetId === this.target.id),
        untilDestroyed(this)
      )
      .subscribe(() => this.loadComments());
  }
}

import { module } from "angular";
import angularInview from "angular-inview";
import badgesCore from "@gtmhub/badges/badges-core.module";
import comments from "@gtmhub/comments/module";
import { FeedCommentsThreadCtrl } from "@gtmhub/feed/controllers/feed-comments-thread";
import { FeedActions } from "@gtmhub/feed/redux/feed-actions";
import { CommentsAndReactionsPermissionsService } from "@webapp/comments/services/comments-and-reactions-permissions.service";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { FeedCtrl } from "./controllers/feed";
import { DrawProgressBar } from "./directives/draw-progress-bar/draw-progress-bar";

const mod = module("feed", [angularInview, comments, badgesCore]);

mod.controller("FeedCtrl", FeedCtrl);
mod.controller("FeedCommentsThreadCtrl", FeedCommentsThreadCtrl);
mod.service("FeedActions", FeedActions);
mod.directive("drawProgressBar", DrawProgressBar.factory());
mod.downgradeInjectable("GoalsFacade", GoalsFacade);
mod.downgradeInjectable("CommentsAndReactionsPermissionsService", CommentsAndReactionsPermissionsService);

export default mod.name;

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { IIndicatorMap } from "@gtmhub/error-handling";
import { AccountResolverService } from "@webapp/accounts";
import { KpiDetailsStateService } from "@webapp/kpis/components/kpi-details/services/kpi-details-state.service";
import { KpiIdSnapshotsMap, KpiSnapshot } from "@webapp/kpis/models/kpi-snapshot.models";
import { KpiSnapshotsFacade } from "@webapp/kpis/services/kpi-snapshots/kpi-snapshots-facade.service";
import { Link } from "@webapp/links/models/links.models";
import { IRelatedItemsSelectorEmission, RelatedItemType } from "@webapp/links/models/related-items.models";
import { AutomatingKpiSelectionData } from "@webapp/shared/components/item-automation-selector/models/item-automation-selector.models";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";

@UntilDestroy()
@Component({
  selector: "kr-with-kpi-automation",
  templateUrl: "./kr-with-kpi-automation.component.html",
  styleUrls: ["./kr-with-kpi-automation.component.less"],
  providers: [KpiDetailsStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KrWithKPIAutomationComponent {
  public allowedTypes: RelatedItemType[] = ["kpi"];
  public relationsToExclude: Set<Link>;
  public isKpiSelected = false;
  public selectedKpiItem: IRelatedItemsSelectorEmission;
  public kpiCurrentValue: number;
  public indicators: IIndicatorMap = {
    loadingKpiValue: null,
  };
  public isQuantiveTeamsEditionPlan = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalRef: UiModalRef,
    private kpiSnapshotsFacade: KpiSnapshotsFacade,
    private accountResolverService: AccountResolverService
  ) {
    const account = this.accountResolverService.getAccountData();
    this.isQuantiveTeamsEditionPlan = account ? account.edition.name === "quantive-teams" : false;
  }

  public onConfirm(): void {
    const selectedKpiData: Pick<AutomatingKpiSelectionData, "kpiId" | "initialValue"> = {
      kpiId: this.selectedKpiItem.id,
      initialValue: this.kpiCurrentValue,
    };

    this.modalRef.destroy(selectedKpiData);
  }

  public onUpdateValue(item: IRelatedItemsSelectorEmission): void {
    if (!item) {
      this.isKpiSelected = false;
      return;
    }
    this.indicators.loadingKpiValue = { progress: true };
    this.selectedKpiItem = item;

    this.getKpiSnapshots$(item.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (snapshots) => {
          this.isKpiSelected = true;
          this.kpiCurrentValue = this.getKpiCurrentValue(snapshots[item.id]);
          delete this.indicators.loadingKpiValue;

          this.cdr.markForCheck();
        },
        error: (error) => {
          this.indicators.loadingKpiValue = { error };

          this.cdr.markForCheck();
        },
      });
  }

  private getKpiCurrentValue(snapshots: KpiSnapshot[]): number {
    return snapshots.length > 0 ? snapshots[snapshots.length - 1].value : 0;
  }

  private getKpiSnapshots$(kpiId: string): Observable<KpiIdSnapshotsMap> {
    const timeZone = this.accountResolverService.getTimezone();

    return this.kpiSnapshotsFacade.getKpiSnapshots$(kpiId, { groupBy: "day", timeZone });
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { getCurrentUserId } from "@gtmhub/users";
import { Comment } from "../models/comments.models";
import { CommentsRepositoryV2 } from "./comments-repository-v2.service";

type Metadata = { [key: string]: string | boolean };

@Injectable({
  providedIn: "root",
})
export class CommentsFacade {
  constructor(private commentsRepositoryV2: CommentsRepositoryV2) {}

  public deleteComment$({ commentId, commentAuthorId, metadata }: { commentId: string; commentAuthorId: string; metadata?: Metadata }): Observable<Comment> {
    const isCurrentUserCommentAuthor = commentAuthorId === getCurrentUserId();

    if (isCurrentUserCommentAuthor) {
      return this.commentsRepositoryV2.deleteOwnComment$({ commentId, metadata });
    }

    return this.commentsRepositoryV2.deleteComment$({ commentId, metadata });
  }
}

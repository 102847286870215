import { Injectable } from "@angular/core";
import { Observable, map, zip } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { AccountResolverService } from "@webapp/accounts";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";

@Injectable()
export class SystemOptionsVisibilityService {
  private account: IAccount;

  constructor(
    private featureModuleService: FeatureModuleService,
    private permissionsFacade: PermissionsFacade,
    private editionFeatureService: EditionFeatureService,
    private accountResolverService: AccountResolverService
  ) {
    this.account = this.accountResolverService.getAccountData();
  }

  get anyOption$(): Observable<boolean> {
    return zip(
      this.methodology$,
      this.tagManagement$,
      this.language$,
      this.remindersAndReport$,
      this.features$,
      this.customFields$,
      this.insightboardParameters$,
      this.automation$,
      this.accountNotifications$
    ).pipe(
      map(([methodology, tagManagement, language, remindersAndReport, features, customFields, insightboardParameters, automation, accountNotifications]) => {
        return methodology || tagManagement || language || remindersAndReport || features || customFields || insightboardParameters || automation || accountNotifications;
      })
    );
  }

  get methodology$(): Observable<boolean> {
    return this.permissionsFacade.hasPermission$("ManageConfiguration");
  }

  get tagManagement$(): Observable<boolean> {
    return this.featureModuleService.isTagsModuleEnabled$();
  }

  get language$(): Observable<boolean> {
    return this.permissionsFacade.hasPermission$("ManageConfiguration");
  }

  get remindersAndReport$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("notifications.kr-reminders")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get features$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("modularity")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get customFields$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("okrs.custom-fields")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get insightboardParameters$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("setup.insights")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get automation$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageData"), this.editionFeatureService.hasFeature$("automation")).pipe(
      map(([manageDataPermission, featureAvailable]) => {
        if (this.account && this.account.edition.name === "quantive-teams") return false;
        return manageDataPermission && featureAvailable;
      })
    );
  }

  get accountNotifications$(): Observable<boolean> {
    return this.permissionsFacade.hasPermission$("ManageAccountNotifications");
  }
}

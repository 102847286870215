import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ICollection } from "@webapp/core/core.models";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { Whiteboard } from "../models/whiteboard.models";
import { WhiteboardsApiV1Service } from "./whiteboards-api-v1.service";

@Injectable({ providedIn: "root" })
export class WhiteboardsRepository implements Pick<IBaseRepository<Whiteboard>, "getAll$"> {
  constructor(private api: WhiteboardsApiV1Service) {}

  public getAll$<RT = ICollection<Whiteboard>>(params?: { filter?: RequestPaging; config?: RequestConfig }): Observable<RT> {
    return this.api.getAll$<RT>(params.filter, params.config);
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { AssigneeDetailsPopoverComponent } from "@webapp/assignees/components/assignee-details-popover/assignee-details-popover.component";
import { UIErrorHandlingServiceProvider } from "@webapp/error-handling/providers/ui-error-handling-service.provider";
import { FileserverModule } from "@webapp/fileserver/fileserver.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ButtonSelectModule } from "@webapp/shared/components/button-select/button-select.module";
import { GifViewModule } from "@webapp/shared/components/gifs/gif-view.module";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { SharedModalModule } from "@webapp/shared/modal/shared-modal.module";
import { TimeAgoPipe } from "@webapp/shared/pipes/time-ago/time-ago.pipe";
import { RichTextEditorModule } from "@webapp/shared/rich-text-editor/rich-text-editor.module";
import { CommentOptionsDropdownComponent } from "./components/comment-options-dropdown/comment-options-dropdown.component";
import { CommentSkeletonComponent } from "./components/comment-skeleton/comment-skeleton.component";
import { CommentComponent } from "./components/comment/comment.component";
import { CommentsOrderingComponent } from "./components/comments-ordering/comments-ordering.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { MentionsEditorComponent } from "./components/mentions-editor/mentions-editor.component";
import { ReactionsComponent } from "./components/reactions/reactions.component";
import { ShowCommentsButtonComponent } from "./components/show-comments-button/show-comments-button.component";
import { HasCommentsAndReactionsPermissionDirective } from "./directives/has-comments-and-reactions-permission.directive";
import { ReactionsFacade } from "./services/reactions-facade.service";

@NgModule({
  imports: [
    CommonModule,
    LocalizationModule,
    AssigneesModule,
    UiButtonModule,
    UiTooltipModule,
    DropdownModule,
    AnalyticsModule,
    RichTextEditorModule,
    GifViewModule,
    UiIconModule,
    TimeAgoPipe,
    UiSkeletonModule,
    SharedModalModule,
    ButtonSelectModule,
    FileserverModule,
    AssigneeDetailsPopoverComponent,
    HasCommentsAndReactionsPermissionDirective,
  ],
  declarations: [
    CommentsComponent,
    CommentComponent,
    MentionsEditorComponent,
    CommentOptionsDropdownComponent,
    ShowCommentsButtonComponent,
    ReactionsComponent,
    CommentSkeletonComponent,
    CommentsOrderingComponent,
  ],
  providers: [UIErrorHandlingServiceProvider, ReactionsFacade],
  exports: [
    CommentsComponent,
    CommentComponent,
    MentionsEditorComponent,
    CommentSkeletonComponent,
    ShowCommentsButtonComponent,
    ReactionsComponent,
    CommentsOrderingComponent,
  ],
})
export class CommentsModule {}

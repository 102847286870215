import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, switchMap } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { IAllowedActionsMap } from "@webapp/sessions/models/sessions.model";
import { GranularWhiteboardPermissionsService } from "./granular-whiteboard-permissions.service";

type NegateParam = { negate: boolean | undefined };

export type CurrentUserAllowedActionsParamType = (keyof IAllowedActionsMap)[] | IAllowedActionsMap;

@Injectable({ providedIn: "root" })
export class WhiteboardPermissionsService {
  constructor(
    private featureModuleService: FeatureModuleService,
    private editionFeatureService: EditionFeatureService,
    private granularWhiteboardPermissionsService: GranularWhiteboardPermissionsService
  ) {}

  public hasPermissionToView$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isWhiteboardsFeatureEnabledForAccount$(), this.granularWhiteboardPermissionsService.hasPermissionToView$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToCreate$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isWhiteboardsFeatureEnabledForAccount$(), this.granularWhiteboardPermissionsService.hasPermissionToCreate$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToEdit$(currentUserAllowedActions: CurrentUserAllowedActionsParamType, { negate }: NegateParam = { negate: false }): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(currentUserAllowedActions);

    return combineLatest([
      this.isWhiteboardsFeatureEnabledForAccount$(),
      this.granularWhiteboardPermissionsService.hasPermissionToEdit$(),
      of(currentUserAllowedActionsMap.update),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToModifyPermissions$(
    currentUserAllowedActions: CurrentUserAllowedActionsParamType,
    { negate }: NegateParam = { negate: false }
  ): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(currentUserAllowedActions);

    return combineLatest([
      this.isWhiteboardsFeatureEnabledForAccount$(),
      this.granularWhiteboardPermissionsService.hasPermissionToEdit$(),
      of(currentUserAllowedActionsMap.modifyPermissions),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToDelete$(currentUserAllowedActions: CurrentUserAllowedActionsParamType, { negate }: NegateParam = { negate: false }): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(currentUserAllowedActions);

    return combineLatest([
      this.isWhiteboardsFeatureEnabledForAccount$(),
      this.granularWhiteboardPermissionsService.hasPermissionToDelete$(),
      of(currentUserAllowedActionsMap.delete),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToManageTemplates$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([
      this.isWhiteboardsFeatureEnabledForAccount$(),
      this.editionFeatureService.hasFeature$("whiteboard-templates"),
      this.granularWhiteboardPermissionsService.hasPermissionToManageTemplates$(),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  private isWhiteboardsFeatureEnabledForAccount$(): Observable<boolean> {
    return combineLatest([this.featureModuleService.isWhiteboardModuleEnabled$(), this.editionFeatureService.hasFeature$("whiteboards")]).pipe(
      map((conditions) => conditions.every((condition) => condition))
    );
  }

  private negateIfNecessary$({ hasPermission, negate }: { hasPermission: boolean; negate: boolean }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;
    return of(hasPermission !== shouldNegate);
  }

  private currentUserAllowedActionsParamToMap(currentUserAllowedActions: CurrentUserAllowedActionsParamType): IAllowedActionsMap {
    return Array.isArray(currentUserAllowedActions) ? currentUserAllowedActions.reduce((acc, action) => ((acc[action] = true), acc), {}) : currentUserAllowedActions;
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { take } from "rxjs";
import { OKRsEventType } from "@gtmhub/okrs/events";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { CommentsRepository, CreateCommentDTO } from "@webapp/comments";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { OkrStatus } from "@webapp/okrs/goals/models/goal-status.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { FormGroupComponent } from "@webapp/shared/form/form-group.component";
import { NoteWithGifAndMentions } from "@webapp/shared/rich-text-editor-with-mentions/rich-text-editor-with-mentions.component";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type OkrChangeStateModalData = {
  goalId: string;
  goalName: string;
  selectedStatus: OkrStatus;
  oldStatus: OkrStatus;
};

interface ChangeOkrStateForm {
  okrStateOption: OkrStatus;
  okrAbandonedReason: string;
}

@Component({
  selector: "change-okr-state-modal",
  templateUrl: "./change-okr-state.component.html",
  styleUrls: ["./change-okr-state.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOkrStateComponent implements OnInit {
  public goalId: string;
  public goalName: string;
  public selectedStatus: OkrStatus;
  public oldStatus: OkrStatus;

  public reason: string;
  public chosenState: OkrStatus;
  public okrComment: CreateCommentDTO;

  public stateItems: string[] = ["achieve_okr", "abandon_okr"];
  public abandonReasonItems: string[] = ["not_enough_time", "not_a_priority_for_this_session", "blocked_until_next_session", "change_in_approach", "none_of_the_above"];

  public abandonReasonsMap: { [key: string]: string } = {
    not_enough_time: "Not enough time",
    not_a_priority_for_this_session: "Not a priority for this session",
    blocked_until_next_session: "Blocked until next session",
    change_in_approach: "Change in approach",
    none_of_the_above: "None of the above",
  };
  @ViewChild(FormGroupComponent, { static: true })
  public form: FormGroupComponent<ChangeOkrStateForm>;

  constructor(
    @Inject(UI_MODAL_DATA) modalData: OkrChangeStateModalData,
    private commentsRepository: CommentsRepository,
    private goalsFacade: GoalsFacade,
    private analyticsService: AnalyticsService,
    private broadcastService: BroadcastService
  ) {
    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    this.okrComment = {
      targetId: this.goalId,
      targetTitle: this.goalName,
      targetType: "goal",
      mentioned: [],
      text: "",
    };
    this.reason = this.abandonReasonItems[0];
    this.chosenState = this.oldStatus;
  }

  public changeAbandonReason = (reason: string): void => {
    this.reason = reason;
  };

  public changeComment = (comment: NoteWithGifAndMentions): void => {
    this.okrComment.text = comment.note;
    this.okrComment.mentioned = [...comment.mentions.removable, ...comment.mentions.nonRemovable];
  };

  public changeOkrState = (state: string): void => {
    const value = state === "abandon_okr" ? "abandoned" : "achieved";
    this.chosenState = value as OkrStatus;
  };

  public saveGoalStatus = (): void => {
    if (this.chosenState === "abandoned") {
      this.abandonGoal();
    } else if (this.chosenState === "achieved") {
      this.achieveGoal();
    } else {
      this.reopenGoal();
    }
  };

  private abandonGoal = (): void => {
    this.goalsFacade.abandonGoal$(this.goalId, this.abandonReasonsMap[this.reason]).subscribe(() => {
      this.trackStateChange();
      this.updateComment();
      this.refreshGoal();
    });
  };

  private achieveGoal = (): void => {
    this.goalsFacade.achieveGoal$(this.goalId).subscribe(() => {
      this.updateComment();
      this.trackStateChange();
      this.refreshGoal();
    });
  };

  private updateComment = (): void => {
    if (this.okrComment.text || this.okrComment.mentioned.length > 0) {
      this.commentsRepository
        .postComment$(this.okrComment, {
          targetId: this.goalId,
          targetType: "goal",
        })
        .pipe(take(1))
        .subscribe((newComment) => {
          this.broadcastService.emit("commentPostedFromWorkflowUpdate", { targetId: newComment.targetId, targetType: newComment.targetType });
          this.broadcastService.emit("commentPostedExternally", newComment.targetId);
        });
    }
  };

  public reopenGoal = (): void => {
    this.goalsFacade.reopenGoal$(this.goalId).subscribe(() => {
      this.analyticsService.track("Objective State Changed", {
        new_state: this.chosenState,
        old_state: this.oldStatus,
        comment_posted: !!this.okrComment,
        reason: this.reason ? this.reason : "",
      });
      this.updateComment();
      this.refreshGoal();
    });
  };

  private refreshGoal = (): void => {
    this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalChanged" });
    this.broadcastService.emit("goalsChanged");
  };

  private trackStateChange(): void {
    this.analyticsService.track("Objective State Changed", {
      new_state: this.chosenState,
      old_state: this.oldStatus,
      comment_posted: !!this.okrComment,
      reason: this.reason,
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { Comment } from "../models/comments.models";

@Injectable({ providedIn: "root" })
export class CommentsApiV2Service extends BaseApiServiceV2<Comment, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("comments", new ApiVersionModel({ version: "v2", trailingSlash: true }), httpClient, appConfig);
  }

  public deleteCommentEndpoint(commentId: string): string {
    return `${this.getBasePath(HttpActions.delete)}${commentId}`;
  }

  public deleteOwnCommentEndpoint(commentId: string): string {
    return `${this.getBasePath(HttpActions.delete)}own/${commentId}`;
  }
}

import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { Comment } from "../models/comments.models";
import { CommentsApiV2Service } from "./comments-api-v2.service";

type Metadata = { [key: string]: string | boolean };

@Injectable({ providedIn: "root" })
export class CommentsRepositoryV2 {
  constructor(private api: CommentsApiV2Service) {}

  public deleteComment$({ commentId, metadata }: { commentId: string; metadata?: Metadata }): Observable<Comment> {
    return this.api.delete$(commentId, {
      ...new RequestConfig(),
      url: this.api.deleteCommentEndpoint(commentId),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    });
  }

  public deleteOwnComment$({ commentId, metadata }: { commentId: string; metadata?: Metadata }): Observable<Comment> {
    return this.api.delete$(commentId, {
      ...new RequestConfig(),
      url: this.api.deleteOwnCommentEndpoint(commentId),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    });
  }
}

import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { CommentsAndReactionsPermissionsService } from "../services/comments-and-reactions-permissions.service";

type NegateParam = { negate: boolean };

export type SupportedCommentsAndReactionsPermissionCheck = "hasPermissionToCreateCommentsAndReactions" | "hasPermissionToDeleteComment";

type HasCommentsAndReactionsPermissionDirectiveParams = {
  hasPermissionToCreateCommentsAndReactions: NegateParam;
  hasPermissionToDeleteComment: NegateParam & { commentAuthorId: string };
};

type IHasCommentsAndReactionsPermissionDirective = {
  [Check in SupportedCommentsAndReactionsPermissionCheck]: HasCommentsAndReactionsPermissionDirectiveParams[Check];
};

/**
 * @example *hasPermissionToCreateCommentsAndReactions="{ negate: false }"
 * @example *hasPermissionToDeleteComment="{ negate: true }"
 */
@UntilDestroy()
@Directive({
  selector: "[hasPermissionToCreateCommentsAndReactions], [hasPermissionToDeleteComment]",
  standalone: true,
})
export class HasCommentsAndReactionsPermissionDirective implements OnInit, IHasCommentsAndReactionsPermissionDirective {
  private checkToBeExecuted: {
    checkFor$: BehaviorSubject<SupportedCommentsAndReactionsPermissionCheck>;
    negate: boolean;
    commentAuthorId?: string;
  } = {
    checkFor$: new BehaviorSubject(null),
    negate: false,
  };

  @Input()
  public set hasPermissionToCreateCommentsAndReactions(value: NegateParam) {
    this.checkToBeExecuted.negate = this.negateParamsToBoolean(value);
    this.checkToBeExecuted.checkFor$.next("hasPermissionToCreateCommentsAndReactions");
  }

  @Input()
  public set hasPermissionToDeleteComment(value: NegateParam & { commentAuthorId: string }) {
    this.checkToBeExecuted.negate = this.negateParamsToBoolean(value);
    this.checkToBeExecuted.commentAuthorId = value.commentAuthorId;
    this.checkToBeExecuted.checkFor$.next("hasPermissionToDeleteComment");
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private permissionsFacade: CommentsAndReactionsPermissionsService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.viewContainerRef.clear();

    this.subscribeToPermissionCheck();
  }

  private subscribeToPermissionCheck(): void {
    const actionMap: {
      [P in SupportedCommentsAndReactionsPermissionCheck]: () => Observable<boolean>;
    } = {
      hasPermissionToCreateCommentsAndReactions: () => this.permissionsFacade.hasPermissionToCreateCommentsAndReactions$({ negate: this.checkToBeExecuted.negate }),
      hasPermissionToDeleteComment: () =>
        this.permissionsFacade.hasPermissionToDeleteComment$({ commentAuthorId: this.checkToBeExecuted.commentAuthorId }, { negate: this.checkToBeExecuted.negate }),
    };

    this.checkToBeExecuted.checkFor$
      .pipe(
        switchMap((checkToBeExecuted) => actionMap[checkToBeExecuted]()),
        untilDestroyed(this)
      )
      .subscribe((hasPermission) => {
        if (hasPermission) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }

        this.cdr.detectChanges();
      });
  }

  private negateParamsToBoolean(params: NegateParam): boolean {
    return typeof params === "string" ? false : params.negate;
  }
}

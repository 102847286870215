import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { localize } from "@webapp/localization/utils/localization.utils";

@Component({
  selector: "empty-filter-results",
  templateUrl: "empty-filter-results.component.html",
  styleUrls: ["empty-filter-results.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class EmptyFilterResultsComponent {
  @Input() public title: string = localize("no_results");

  public descriptionString: string | null = null;
  public descriptionTemplateRef: TemplateRef<object> | null = null;

  @Input()
  set description(value: string | TemplateRef<object>) {
    if (!value) {
      value = localize("adjust_the_filter_and_try_again");
    }

    if (value instanceof TemplateRef) {
      this.descriptionTemplateRef = value;
      this.descriptionString = null;
    } else {
      this.descriptionTemplateRef = null;
      this.descriptionString = value;
    }
  }
}

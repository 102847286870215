import { IComponentOptions, IController } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { StateService } from "@uirouter/angular";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { ChargebeeService } from "@gtmhub/configuration/services/chargebee-service";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { IUpgradePlan } from "@webapp/configuration/models/configuration.model";
import { SubscriptionRepository } from "@webapp/configuration/services/subscription/subscription-repository.service";
import { CurrentUserRepository } from "@webapp/users";
import { IPlan, IPlanLabel } from "../../models";
import { FeatureUsageService } from "../../services/feature-usage.service";
import { getPlanLabel } from "../../utils";

interface IPlanDetailsBindings {
  plan: IPlan;
  selectedPlan: IPlan;
  accountId: string;
  isAccountActive?: boolean;
  isAccountTrial?: boolean;
  modalClose(args: { reason: unknown }): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface PlanDetailsCtrl extends IPlanDetailsBindings {}
class PlanDetailsCtrl implements IController {
  planLabel: IPlanLabel;

  static $inject = [
    "$uibModal",
    "$state",
    "AccountService",
    "AnalyticsService",
    "ChargebeeService",
    "CurrentUserRepository",
    "FeatureUsageService",
    "UIErrorHandlingService",
    "SubscriptionRepository",
  ];

  constructor(
    private $uibModal: IModalService,
    private $state: StateService,
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private chargebeeService: ChargebeeService,
    private currentUserRepository: CurrentUserRepository,
    private featureUsageService: FeatureUsageService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private subscriptionRepository: SubscriptionRepository
  ) {}

  $onInit(): void {
    this.planLabel = getPlanLabel(this.plan.editionName);
  }

  openUpgradeModal(): void {
    if (this.shouldShowFeatureLossModal()) {
      this.getFeatureUsageAndDisplayModal({ isBuyAction: false });
    } else {
      this.upgradeEdition();
    }
  }

  openCbCheckout(): void {
    if (this.shouldShowFeatureLossModal()) {
      this.getFeatureUsageAndDisplayModal({ isBuyAction: true });
    } else {
      this.checkValidEmailAndProceedToCheckout();
    }
  }

  contactSales(): void {
    this.analyticsService.contactSales("contactSales");
  }

  isCustomQuote(): boolean {
    const invalidPlans = ["Enterprise", "Business", "Gold", "Silver", "Platinum", "Quantive StrategyAI", "Gold Trial", "Gold Consulting"];
    return invalidPlans.includes(this.planLabel.name);
  }

  private shouldShowFeatureLossModal(): boolean {
    return this.plan.weight < this.selectedPlan.weight && this.selectedPlan.editionName !== this.plan.editionName;
  }

  private upgradeEdition(): void {
    const editionUpgrade: IUpgradePlan = {
      edition: this.plan.editionName,
      billingPeriod: this.plan.billingPeriod,
    };

    this.subscriptionRepository.upgradeEditionPlan$(editionUpgrade).subscribe({
      next: () => {
        this.$state.go(".", {}, { reload: true });
      },
    });
  }

  private checkValidEmailAndProceedToCheckout(): void {
    const isEmailVerified = this.currentUserRepository.getUserSetting<boolean>("emailVerified");

    if (!isEmailVerified) {
      this.$uibModal
        .open({
          template: require("../../views/required-email-notification.html"),
          backdrop: false,
          windowClass: "modal-small",
          backdropClass: "dark-backdrop",
          controller: "RequiredEmailNotificationCtrl",
        })
        .result.then(() => this.getCheckoutPage());

      return;
    }

    this.getCheckoutPage();
  }

  private getFeatureUsageAndDisplayModal({ isBuyAction }: { isBuyAction: boolean }): void {
    this.featureUsageService.getFeatureUsageForUserId(this.accountId, this.plan.editionName).then(
      ({ features }) => {
        if (features?.length) {
          this.$uibModal
            .open({
              template: require("../../views/downgrade-feature-loss.html"),
              windowClass: "medium-small",
              backdropClass: "dark-backdrop",
            })
            .result.then(() => this.processToNextModal({ isBuyAction }));
        } else {
          this.processToNextModal({ isBuyAction });
        }
      },
      (error) => {
        this.uiErrorHandlingService.handleModal(error);
      }
    );
  }

  private processToNextModal({ isBuyAction }: { isBuyAction: boolean }): void {
    if (isBuyAction) {
      this.checkValidEmailAndProceedToCheckout();
    } else {
      this.upgradeEdition();
    }
  }

  private getCheckoutPage(): void {
    this.accountService.getCheckoutPage({ planGroupName: this.plan.planGroupName, billingPeriod: this.plan.billingPeriod, edition: this.plan.editionName }).then(
      (checkoutPageData) => {
        this.chargebeeService.openCbCheckout(checkoutPageData.url);
      },
      (error) => this.uiErrorHandlingService.handleModal(error)
    );
  }
}

export const PlanDetailsComponent: IComponentOptions = {
  bindings: {
    plan: "<",
    selectedPlan: "<",
    accountId: "<",
    isAccountTrial: "<",
    alignButtonsToTop: "<",
    modalClose: "&",
  },
  controller: PlanDetailsCtrl,
  template: require("./plan-details.html"),
};

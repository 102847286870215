import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { ISessionSelectorContext, ISessionData as ISessionSelectorData } from "../models/session-tree.model";
import { SessionListDataTransformerService } from "./session-data-transformers/session-list-data-transformer.service";
import { SessionTreeDataTransformerService } from "./session-data-transformers/session-tree-data-transformer.service";
import { SessionSelectorUtilsService } from "./session-selector-utils.service";

@Injectable({
  providedIn: "root",
})
export class SessionSelectorFacade {
  constructor(
    private usersFacade: UsersFacade,
    private sessionSelectorUtilService: SessionSelectorUtilsService,
    private sessionTreeDataTransformerService: SessionTreeDataTransformerService,
    private sessionListDataTransformerService: SessionListDataTransformerService
  ) {}

  public getSessionData$(context: ISessionSelectorContext): Observable<ISessionSelectorData> {
    return new ReduxStoreObserver(reduxStoreContainer.reduxStore).whenFetched$<ISessionsStoreState>("sessions").pipe(
      map(({ sessions }) => {
        const sessionsSetWithPermissions =
          context.sessionsFilter?.(sessions.items) || this.sessionSelectorUtilService.filterOpenSessionsInWhichUserHasRightToCreate(sessions.items);
        const tree = this.sessionTreeDataTransformerService.buildTree(sessionsSetWithPermissions, context);
        const list = this.sessionListDataTransformerService.buildList(tree);

        return { tree, list };
      })
    );
  }

  // The sessions are loaded from redux and are loaded before initialisation of session-selector as they are fetched in goals/form.ts
  public isLoading$(): Observable<boolean> {
    return this.usersFacade.getState().isLoading$(HttpActions.get);
  }
}

import { Injectable } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { FilterSettings } from "@webapp/filters/models/filter.models";
import { isDateInThePast } from "@webapp/home/components/widgets/todos-widget/pipes/utils";
import dayjs from "@webapp/shared/libs/dayjs";
import { CurrentUserRepository } from "@webapp/users";
import { ISessionTreeNode } from "../models/session-tree.model";
import { PlanningSessionStatus, Session, SessionSelectorSettings } from "../models/sessions.model";

const isSomeNodeInBranchAnOpenSession = (session: Session): boolean => {
  if (session.status === PlanningSessionStatus.OPEN) {
    return true;
  }

  if (!session.children.length) {
    return false;
  }

  for (const child of session.children) {
    const isSomeNodeInChildBranchAnOpenSession = isSomeNodeInBranchAnOpenSession(child);

    if (isSomeNodeInChildBranchAnOpenSession) {
      return true;
    }
  }

  return false;
};

const isSomeNodeInBranchNonArchivedSession = (session: Session): boolean => {
  if (session.status !== PlanningSessionStatus.ARCHIVED) {
    return true;
  }

  if (!session.children.length) {
    return false;
  }

  for (const child of session.children) {
    const isSomeNodeInChildBranchAnOpenSession = isSomeNodeInBranchNonArchivedSession(child);

    if (isSomeNodeInChildBranchAnOpenSession) {
      return true;
    }
  }

  return false;
};

function getSettingsByPriority(prioritySettings: SessionSelectorSettings, userSettings: FilterSettings): SessionSelectorSettings {
  const settings: SessionSelectorSettings = {
    current: prioritySettings.current || userSettings.find((setting) => setting.label === localize("current_sessions")).value,
    past: prioritySettings.past || userSettings.find((setting) => setting.label === localize("past_sessions")).value,
    future: prioritySettings.future || userSettings.find((setting) => setting.label === localize("future_sessions")).value,
    archived: prioritySettings.archived || userSettings.find((setting) => setting.label === localize("archived_sessions")).value,
  };

  return settings;
}

@Injectable({
  providedIn: "root",
})
export class SessionSelectorUtilsService {
  constructor(private currentUserRepository: CurrentUserRepository) {}

  // IMPORTANT! because of this being a default condition it could be called from a place where this.currentUserRepository is not defined
  // to avoid that, this needs to be arrow function so that 'this' does not create it's own context
  // this is the default filter condition in order not to change the existing behavior
  public filterOpenSessionsInWhichUserHasRightToCreate = (sessions: Session[]): Session[] => {
    for (const session of sessions) {
      if (session.status === PlanningSessionStatus.OPEN) {
        session.userHasPermissionToCreate = this.currentUserRepository.allowedActionsSync(session.access).includes("create");
      } else if (isSomeNodeInBranchAnOpenSession(session)) {
        session.userHasPermissionToCreate = false;
      } else {
        sessions = sessions.filter((s) => s !== session);
      }
    }

    return sessions;
  };

  public filterNonArchivedSessionsInWhichUserHasRightToRead = (sessions: Session[]): Session[] => {
    for (const session of sessions) {
      if (session.status !== PlanningSessionStatus.ARCHIVED) {
        session.userHasPermissionToCreate = this.currentUserRepository.allowedActionsSync(session.access).includes("read");
      } else if (isSomeNodeInBranchNonArchivedSession(session)) {
        session.userHasPermissionToCreate = false;
      } else {
        sessions = sessions.filter((s) => s !== session);
      }
    }

    return sessions;
  };

  public filterSessionsBasedOnSettings = (sessions: Session[], userSettings: FilterSettings, prioritySettings: SessionSelectorSettings): Session[] => {
    const today = dayjs().startOf("day");
    const settingValues = getSettingsByPriority(prioritySettings, userSettings);

    return sessions.filter((session) => {
      const sessionStartDate = dayjs(session.start);
      const sessionEndDate = dayjs(session.end);
      const isArchived = session.status === PlanningSessionStatus.ARCHIVED;

      const isCurrent = settingValues.current && sessionStartDate.isSameOrBefore(today) && sessionEndDate.isSameOrAfter(today) && !isArchived;
      const isPast = settingValues.past && isDateInThePast(sessionEndDate) && !isArchived;
      const isFuture = settingValues.future && sessionStartDate.isAfter(today) && !isArchived;
      const isArchivedSetting = settingValues.archived && isArchived;

      const shouldInclude = isCurrent || isPast || isFuture || isArchivedSetting;

      if (shouldInclude) {
        session.userHasPermissionToCreate = this.currentUserRepository.allowedActionsSync(session.access).includes("read");
      }

      return shouldInclude;
    });
  };

  public fillSessionWithPermissions(sessions: Session[]): Session[] {
    // we copy the sessions because we do not want to have the passed reference include the permissions,
    // since they are only relevant for the caller of the method
    sessions = structuredClone(sessions);

    return this.filterOpenSessionsInWhichUserHasRightToCreate(sessions);
  }

  public isAnyChild(parent: ISessionTreeNode, predicate: (child: ISessionTreeNode) => boolean): boolean {
    if (!parent.children || !parent.children.length) {
      return false;
    }

    return parent.children.some((child) => this.isAnyChild(child, predicate));
  }
}

import { IPlan } from ".";
import { IPlanLabel } from "./models";

type PlanLabel =
  | "essentials"
  | "scale"
  | "commercial"
  | "enterprise"
  | "start"
  | "summit"
  | "business"
  | "silver"
  | "enterprise2024"
  | "quantiveStategyAI"
  | "goldConsulting"
  | "teams";

const planLabelMap: Record<PlanLabel, IPlanLabel> = {
  essentials: {
    img: "plan-essentials",
    name: "Essentials",
    desc: "for_smaller_teams_and_organizations_who_are_either_just_getting_started_with_okrs",
    priceDesc: "free_forever",
    billingDescKey: "youre_currently_on_our_free_plan",
    planFeaturesTitle: "essential_okr_capabilities",
    planFeatures: [
      "unlimited_users",
      "alignment_planning_sessions",
      "okr_creation_management",
      "multi_level_okrs",
      "shared_okrs_collaborators",
      "okr_templates_quality_checks",
      "automated_alerts_notifications",
      "user_role_management",
      "slack_teams_integration",
      "mobile_apps",
      "okr_education_resources",
      "standard_support",
    ],
  },
  scale: {
    img: "plan-scale",
    name: "Scale",
    desc: "for_small_to_medium_sized_teams_and_companies_with_well_established_practices",
    priceDesc: "user_month",
    billingDescKey: "youre_currently_on_our_scale",
    planFeaturesTitle: "everything_in_essentials_plus",
    planFeatures: [
      "up_to_249_users",
      "whiteboarding",
      "kpis",
      "project_task_management",
      "okr_performance_dashboards",
      "timeline_management_auditing",
      "lists_reporting",
      "basic_sso",
      "standard_integrations",
      "imports",
      "additional_integrations_via_app_marketplace",
    ],
  },
  commercial: {
    img: "",
    name: "Commercial",
    desc: "",
    priceDesc: "",
    billingDescKey: "youre_currently_on_our_commercial_plan",
    planFeaturesTitle: "",
    planFeatures: [],
  },
  enterprise: {
    img: "plan-enterprise",
    name: "Enterprise",
    desc: "for_larger_teams_and_organizations_that_need_more_advanced_and_custom_capabilities",
    priceDesc: "custom_quote",
    billingDescKey: "youre_currently_on_our_enterprise_plan",
    planFeaturesTitle: "all_the_goodness_of_scale_plus",
    planFeatures: [
      "x_or_more_users",
      "dynamic_key_results",
      "okr_approval_workflow",
      "bulk_okr_actions",
      "robotic_process_automation",
      "user_provisioning_management",
      "saml_sso",
      "api_access",
      "custom_integrations",
      "third_party_apps",
      "custom_development_consulting",
      "exports",
      "enhanced_24_7_support_security",
      "implementation_account_management_services",
    ],
  },
  start: {
    img: "plan-essentials",
    name: "Start",
    desc: "plan_start_description",
    priceDesc: "user_month",
    billingDescKey: "youre_currently_on_our_start",
    planFeaturesTitle: "",
    planFeatures: ["everything_you_need_to_adopt", "okr_alignment_view", "okr_linking_and_cascading", "task_management", "marketplace_reminders_statistics"],
  },
  summit: {
    img: "plan-enterprise",
    name: "Summit",
    desc: "plan_summit_description",
    priceDesc: "user_month",
    billingDescKey: "youre_currently_on_our_summit",
    planFeaturesTitle: "",
    planFeatures: ["everything_in_scale", "automated_user_provisioning_and_sso", "unlimited_access_to_integrations", "approval_workflow", "robotic_process_automation"],
  },
  goldConsulting: {
    img: "plan-gold",
    name: "Gold Consulting",
    desc: "for_larger_departments_and_organizations_that_need_advanced_and_custom_capabilities_including_strategic_planning_security_control",
    priceDesc: "user_month",
    billingDescKey: "youre_currently_on_our_gold_consulting_plan",
    planFeaturesTitle: "all_the_goodness_of_scale_plus",
    planFeatures: [
      "x_or_more_users",
      "strategic_planning",
      "ai_okr_coach",
      "executive_dashboards",
      "dynamic_key_results",
      "okr_approval_workflow",
      "bulk_okr_actions",
      "robotic_process_automation",
      "user_provisioning_management",
      "saml_sso",
      "api_access",
      "custom_integrations",
      "third_party_apps",
      "custom_development_consulting",
      "exports",
      "implementation_account_management_services",
    ],
  },
  silver: {
    img: "plan-silver",
    name: "Silver",
    desc: "for_larger_departments_and_organizations_that_need_advanced_and_custom_capabilities_including_strategic_planning_security_control_and_enhanced_support",
    priceDesc: "custom_quote",
    billingDescKey: "youre_currently_on_our_silver_plan",
    planFeaturesTitle: "all_the_goodness_of_scale_plus",
    planFeatures: [
      "x_or_more_users",
      "strategic_planning",
      "ai_okr_coach",
      "executive_dashboards",
      "dynamic_key_results",
      "okr_approval_workflow",
      "bulk_okr_actions",
      "robotic_process_automation",
      "user_provisioning_management",
      "saml_sso",
      "api_access",
      "custom_integrations",
      "third_party_apps",
      "custom_development_consulting",
      "exports",
      "enhanced_24_7_support_security",
      "implementation_account_management_services",
    ],
  },
  quantiveStategyAI: {
    img: "plan-enterprise",
    name: "Quantive StrategyAI",
    desc: "for_larger_departments_and_organizations_that_need_advanced_and_custom_capabilities_including_strategic_planning_security_control_and_enhanced_support",
    priceDesc: "custom_quote",
    billingDescKey: "youre_currently_on_our_quantive_strategyai",
    planFeaturesTitle: "all_the_goodness_of_scale_plus",
    planFeatures: [
      "x_or_more_users",
      "strategic_planning",
      "ai_okr_coach",
      "executive_dashboards",
      "dynamic_key_results",
      "okr_approval_workflow",
      "bulk_okr_actions",
      "robotic_process_automation",
      "user_provisioning_management",
      "saml_sso",
      "api_access",
      "custom_integrations",
      "third_party_apps",
      "custom_development_consulting",
      "exports",
      "enhanced_24_7_support_security",
      "implementation_account_management_services",
    ],
  },
  teams: {
    img: "plan-commercial", // should add an img for plan-teams
    name: "Teams",
    desc: "For teams wanting to align strategic initiatives and goals, track progress, and drive focus.",
    priceDesc: "price per user per month",
    billingDescKey: "You're currently on our Teams plan",
    planFeaturesTitle: "Features",
    planFeatures: [
      "up_to_100_users",
      "ai_guided_decisions",
      "action_plan_generator",
      "executive_summaries",
      "whiteboards_planning",
      "goal_updates_and_reminders",
      "goal_to_kpi_alignment",
      "task_management",
      "progress_dashboards",
      "kpi_dashboards",
      "team_checkins",
      "two_factor_authentication_future",
    ],
  },
  business: {
    img: "plan-scale",
    name: "Business",
    desc: "For mid-sized organizations wanting to connect their data and unlock efficiency with automations.",
    priceDesc: "custom_quote",
    billingDescKey: "youre_currently_on_our_gold_plan",
    planFeaturesTitle: "All the goodness of Teams, plus",
    planFeatures: [
      "unlimited_users",
      "ai_suggested_goal_improvements",
      "automated_tracking",
      "goal_milestones",
      "kpi_projections",
      "oob_executive_dashboards",
      "standard_sso",
      "view_only_users",
      "account_configuration_support",
      "onboarding_and_product_training",
    ],
  },
  enterprise2024: {
    img: "plan-enterprise",
    name: "Enterprise",
    desc: "For large and data-rich enterprises wanting custom solutions and dedicated support.",
    priceDesc: "custom_quote",
    billingDescKey: "youre_currently_on_our_platinum_plan",
    planFeaturesTitle: "All the goodness of Business, plus",
    planFeatures: [
      "unlimited_users",
      "ai_powered_insights",
      "custom_executive_dashboards",
      "custom_configurations",
      "api_access",
      "custom_integrations",
      "custom_user_permissions",
      "enterprise_user_provisioning",
      "dedicated_support_team",
      "ongoing_support_services",
      "custom_solutions",
    ],
  },
};

export function getPlanLabel(planId: string): IPlanLabel {
  if (planId.includes("scale")) {
    return planLabelMap.scale;
  } else if (planId.includes("essential")) {
    return planLabelMap.essentials;
  } else if (planId.includes("commercial")) {
    return planLabelMap.commercial;
  } else if (planId.includes("start")) {
    return planLabelMap.start;
  } else if (planId.includes("summit")) {
    return planLabelMap.summit;
  } else if (planId.includes("quantive-enterprise-gold-consulting")) {
    return planLabelMap.goldConsulting;
  } else if (planId.includes("quantive-business")) {
    return planLabelMap.business;
  } else if (planId.includes("silver")) {
    return planLabelMap.silver;
  } else if (planId.includes("quantive-enterprise")) {
    return planLabelMap.enterprise2024;
  } else if (planId.includes("quantive-strategy-ai") || planId.includes("gtmhub-enterprise-v2")) {
    return planLabelMap.quantiveStategyAI;
  } else if (planId.includes("quantive-teams")) {
    return planLabelMap.teams;
  } else {
    return planLabelMap.enterprise;
  }
}

export function isAccountWithBasicEdition(plans: IPlan[]): boolean {
  if (!plans.length) {
    return false;
  }

  const mostExpensivePlanWeight = 6;
  const planWithGreatestWeight = [...plans].sort((a, b) => b.weight - a.weight)[0].weight;
  const selectedPlanWeight = plans.find((plan) => plan.isSelected)?.weight || mostExpensivePlanWeight;

  // planWithGreatestWeight - Annual plan from the most expensive plan (currently weight = 6)
  // We want to show this button if the account edition is not the most expensive which means all plans < 5 weight
  // Why all plans < 5 weight? - Currently, the most expensive plan with monthly billing period has 5 weight
  return selectedPlanWeight < planWithGreatestWeight - 1;
}
